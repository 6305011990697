//
// Contextual backgrounds
//

.bg-faded {
  background-color: darken($body-bg, 3%);
}

@include bg-variant('.bg-primary', var(--primary-color));

@include bg-variant('.bg-success', $brand-success);

@include bg-variant('.bg-info', $brand-info);

@include bg-variant('.bg-warning', $brand-warning);

@include bg-variant('.bg-danger', $brand-danger);

@include bg-variant('.bg-inverse', $brand-inverse);
