.preloaderbar {
  z-index: $zindex-modal-backdrop;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 4px;
  margin-bottom: -4px;

  .bar {
    position: absolute;
    width: 100%;
    height: 0;
    text-indent: -9999px;
    background-color: #23b7e5;

    &:before {
      position: absolute;
      right: 50%;
      left: 50%;
      height: 4px;
      background-color: inherit;
      content: "";
    }
  }

  &.active {
    animation: changebar 2.25s infinite 0.75s;
  }
  &.active .bar {
    animation: changebar 2.25s infinite;
  }
  &.active .bar:before {
    animation: movingbar 0.75s infinite;
  }
}

@keyframes movingbar {
  0% {
    right: 50%;
    left: 50%;
  }
  99.9% {
    right: 0;
    left: 0;
  }
  100% {
    right: 50%;
    left: 50%;
  }
}
@keyframes changebar {
  0% {
    background-color: #9CCC65; // light green, 400
  }
  33.3% {
    background-color: #9CCC65;
  }
  33.33% {
    background-color: $brand-warning;
  }
  66.6% {
    background-color: $brand-warning;
  }
  66.66% {
    background-color: $brand-danger;
  }
  99.9% {
    background-color: $brand-danger;
  }
  100% {
    background-color: #9CCC65;
  }
}