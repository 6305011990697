$tia-border: #f0f6fc;
$tia-light-gray: #cad5e6;
$tia-green: #4fd9c3;
$tia-dark-blue: #0084B8;

:root {
  --tiaPrimary: #009fdf;
  --tiaPrimaryLight: #e5f5fc;
  --tiaPink: #fe637c;
  --tiaPinkLight: #feeff2;
  --tiaYellow: #fce800;
  --tiaYellowLight: #fefee5;
  --tiaOrange: #fa5b0f;
  --tiaGreen: #4fd9c3;
  --tiaGreenLight: #edfbf9;
  --tiaBackground: #f5f6f8;
  --tiaDarkGray: #141638;
  --tiaMediumGray: #585a58;
  --tiaGray: #7b8ca8;
  --tiaLightGray: #cad5e6;
  --tiaBorder: #f0f6fc;

  --tiaPrimaryText: hsl(210, 11%, 15%);
  --tiaSecondaryText: hsl(210, 11%, 55%);
}
