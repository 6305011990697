.ng2-file-input{
    .ng2-file-input-drop-container{
        min-width:200px;
        min-height:50px;
        border: 2px dashed #333;
        padding:7px;
        button{
            margin-left: 25px;
        }
    }
    .ng2-file-input-drop-container.file-is-over{
        border: 2px solid #007e40;
        color: #007e40;
    }
    input{
        display:none;
    }
    .ng2-file-input-files{
        margin-top:8px;
        .ng2-file-input-file{
            overflow:hidden;
            display:inline-block;
            margin-right:5px;
            margin-bottom:5px;
            vertical-align: middle;
            text-align: center;
            position:relative;
            .ng2-file-input-file-text{
                max-width:110px;
                line-height:16px;
                display:block;
                padding:2px;
                width:auto;
                overflow:hidden;
                word-wrap: break-word;
                font-size:12px;
            }
            img{
                width:120px;
                height:120px;
            }
        }
        .ng2-file-input-file.image{
            width:120px;
            height:120px;
            .ng2-file-input-file-text{
                position:absolute;
                top:5px;
                left:5px;
                line-height:14px;
                font-size:11px;
                background: rgba(255,255,255,0.6);
                white-space: nowrap;
                z-index:5;
                text-overflow: ellipsis;
            }
            .ng2-file-input-file-text:hover{
                overflow:auto;
                white-space: pre-wrap;
                text-overflow: initial;
            }
        }
        .ng2-file-input-file.image .ng2-file-input-file-text.remove, .ng2-file-input-file-text.remove{
            overflow:hidden;
            position:absolute;
            bottom:0;
            z-index:3;
            vertical-align:bottom;
            max-width:120px;
            font-size:14px;
            line-height:20px;
            top: 0;
            left:0;
            right:0;
            opacity:0;
            padding:0;
            p{
                position:absolute;
                bottom:5px;
                width:100%;
                color: #fff;
            }
        }
        .ng2-file-input-file.image .ng2-file-input-file-text.remove:hover, .ng2-file-input-file-text.remove:hover{
            opacity:1;
            cursor:pointer;
            background: rgba(169,68,66,0.85);
        }
    }
}
