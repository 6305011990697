.quickview-wrapper {
  z-index: $l_zindex_quickview;
  position: fixed;
  top: 0;
  bottom: 0;
  height: 100vh; // Fix(Safari): Fix sidebar fail to on safari
  right: -$l_right_quickview_width;
  width: $l_right_quickview_width;
  background: $l_white;
  transition: right 0.4s $l_transition_ease_out_quickview;
  backface-visibility: hidden;
}

.preview_hover {
  position: fixed;
  padding-left: 20px;
  z-index: 2;
  width: inherit;
}

.zoom_buttons i {
  font-size: 30px;
}

.zoomin {
  float: left;
  position: relative;
  z-index: 1;
}

.quickview-wrapper {
  border-left: 1px solid rgba(0, 0, 0, 0.05);
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
}

.quickview-survey-toggle {
  position: absolute;
  padding-top: 10%;
  padding-bottom: 10%;
  width: 20px;
  height: 40px;
  left: -48px;
  text-align: center;
  line-height: 50px;
  cursor: pointer;
  .material-icons {
    font-size: 16px;
    line-height: 50px;
  }
}
