$padding-for-icon: 40px;

.auth-page {
  .top-nav-left {
    display: inline-block;
    font-size: 16px;
    line-height: 24px;
    float: left;
  }

  .l-banner {
    max-width: 860px;
    margin-left: auto;
    margin-right: auto;
  }

  .banner-content {
    padding-top: 3.75rem;
  }

  @media only screen and (min-width: 60rem) {
    .l-banner {
      padding-left: 15px;
      padding-right: 15px;
    }
  }

  @media only screen and (min-width: 60rem) {
    .h2,
    h2 {
      font-size: 2.25rem;
      line-height: 1.33;
    }
  }

  @media only screen and (min-width: 48rem) {
    .form-wrapper {
      width: 440px;
      margin: auto;
    }
  }

  @media only screen and (min-width: 48rem) {
    .form-wrapper-larger {
      width: 600px !important;
      margin: auto;
    }
  }

  .signup-page-container .form-wrapper {
    margin-bottom: 30px;
  }

  .form-wrapper fieldset {
    margin: 0;
    padding: 0;
  }

  .mat-form-field-wrapper {
    padding-top: 10px;
    padding-bottom: 0px !important;
  }

  .mat-form-field-underline {
    bottom: 0px !important;
  }

  .form-field {
    // font-size: 0;
    font-size: inherit;
    position: relative;
    border-radius: 3px;
    // border: solid 1px #ccc;
    clear: both;
    will-change: margin-bottom;
    margin-bottom: 9px;
    transition: margin-bottom 0.15s ease;

    mat-icon {
      color: #ccc;
      font-size: 1.5rem;
    }

    input {
      width: 100%;
      color: #4d4d4d;
      font-size: 1rem;
      font-family: National-Book, Helvetica, sans-serif;
      transition: top 0.2s ease, font-size 0.2s ease, padding 0.2s ease;
      border: none;
      border-radius: 2px;
      outline: 0;
    }

    .mat-input-wrapper {
      height: 64px;
      padding-bottom: 0 !important;
    }

    .mat-input-flex {
      height: 64px;
    }

    .mat-form-field-placeholder-wrapper {
      left: 40px;
      top: 2px;
    }
  }

  .form-field-no-padding {
    font-size: inherit;
    position: relative;
    border-radius: 3px;
    clear: both;
    will-change: margin-bottom;
    margin-bottom: 9px;
    transition: margin-bottom 0.15s ease;

    input {
      width: 100%;
      color: #4d4d4d;
      font-size: 1rem;
      font-family: National-Book, Helvetica, sans-serif;
      transition: top 0.2s ease, font-size 0.2s ease, padding 0.2s ease;
      border: none;
      border-radius: 2px;
      outline: 0;
    }

    .mat-input-wrapper {
      height: 64px;
      padding-bottom: 0 !important;
    }

    .mat-input-underline {
      bottom: 0;
    }
    .mat-input-flex {
      height: 64px;
    }

    .mat-form-field-placeholder-wrapper {
      left: 10px;
      top: 2px;
    }
  }

  .form-placeholder {
    left: 0;
    top: 14px;
    color: #b3b3b3;
    pointer-events: none;
    transition: top 0.2s ease, font-size 0.2s ease, padding 0.2s ease;
    font-size: 16px;
    padding: 0 10px 10px 46px;
    position: absolute;
  }

  .pattern-gradient-light {
    background-position: bottom left;
    padding-bottom: 140px;
    background-size: 100%;
    padding-top: 105px;
    background-image: url('/assets/background/light.svg'),
      linear-gradient(to bottom, rgba(33, 150, 543, 0.1), transparent);
    background-repeat: no-repeat;
  }
  .login-link {
    display: block;
    font-size: 13px;
    text-align: right;
    a {
      color: #489be8;
      text-decoration: none;
      transition: color 0.3s;
    }
  }

  .btn {
    background-color: var(--primary-color);
    color: #fff;
    padding: 10.5px 30px;
    font-size: 0.875rem;
    vertical-align: bottom;
    font-family: Sailec-Bold, Helvetica, sans-serif;
    cursor: pointer;
    border: none;
    outline: 0;
    border-radius: 3px;
    text-align: center;
    letter-spacing: 1px;
    text-transform: uppercase;
    display: inline-block;
    transition: background-color 0.15s ease-in;
    white-space: normal;
  }

  .btn-secondary {
    background-color: #fff;
    color: var(--primary-color);
    padding: 10.5px 30px;
    font-size: 0.875rem;
    vertical-align: bottom;
    font-family: Sailec-Bold, Helvetica, sans-serif;
    cursor: pointer;
    border: none;
    outline: 0;
    border-radius: 3px;
    text-align: center;
    letter-spacing: 1px;
    text-transform: uppercase;
    display: inline-block;
    transition: background-color 0.15s ease-in;
    white-space: normal;
  }
}

.md-form-auth {
  md-input-container.md-icon-left {
    padding-left: $padding-for-icon;
  }

  md-input-container {
    > md-icon {
      color: $gray-light2;
      margin-top: -5px; // align with label
    }
  }

  button[type='submit'],
  a[type='submit'] {
    margin-right: 0;
  }
}

.page-auth {
  background-color: $gray-lighter;
  min-height: 100%;
  background: url('/assets/waitingroom.svg') no-repeat center center
    fixed;
  background-size: cover;
  padding: 0 10px;

  .card {
    @include z-depth-2;
    padding: 40px 30px;
  }
  .logo {
    padding-left: ($padding-for-icon - 4);
    font-size: 32px;
    &.text-center {
      padding: 0;
    }
    a {
      font-weight: normal;
      text-decoration: none;
    }
    margin-bottom: 1.3em;
  }

  .main-body {
    position: relative;
    max-width: 480px;
    margin: 0 auto;
    padding: 50px 0 20px;
    @media (min-width: map-get($grid-breakpoints, md)) {
      padding-top: 150px;
    }
  }
  .additional-info {
    color: $text-muted;
    background-color: rgba(#000, 0.04);
    padding: 10px 6px;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    text-align: center;
    font-size: $font-size-sm;
    a {
      color: $gray-light;
    }
    .divider-h {
      border-right: 1px solid rgba(#000, 0.1);
      margin: 0 15px;
    }
  }

  //
  &.page-confirm-email {
    .logo {
      font-size: 24px;
      margin-bottom: 0.8em;
    }
  }
  .confirm-mail-icon {
    text-align: center;
    .material-icons {
      color: $gray-light;
      font-size: 100px;
    }
  }
}
