
@mixin bg-style ($background, $color) {
  background-color: $background !important;
  // border-radius: 10px;
  color: $color !important;
  &:hover {
    background-color: $background;
    color: $color;
  }
  a {
    color: $color;
    &:hover {
      color: $color;
    }
  }
}

@mixin text-style ($background, $color) {
  color: $background !important;
}

@mixin border-style ($background, $color) {
  color: $background !important;
  border: $background solid 2px;
}

// dirty fix
.bg-color-dark .bg-color-light a {
  color: $body-color;
}

.bg-color-body { @include bg-style($body-bg, $body-color); }
.bg-color-page { @include text-style($page-bg, $body-color); }
.bg-color-light { @include bg-style($white, $body-color); }
.bg-color-dark { @include bg-style($dark, $white); }
.bg-color-primary { @include bg-style(var(--primary-color), $white); }
.bg-color-success { @include bg-style($brand-success, $white); }
.bg-color-info { @include bg-style($brand-info, $white); }
.bg-color-warning { @include bg-style($brand-warning, $body-color); }
.bg-color-danger { @include bg-style($brand-danger, $white); }

.theme-color-light {
  .bg-color-light { @include bg-style($white, $body-color); }
  .mat-ink-bar { background-color: $body-color !important}
  .primary-color-full { @include bg-style($white, $body-color); }
  .primary-color-text { @include text-style($white, $body-color); }
  .primary-color-border { @include border-style($white, $body-color); }
}

.bg-color-white { @include bg-style($white, $body-color); }
.bg-color-light-gray { background-color: #b9b3b361; }
.bg-color-gray { @include bg-style($gray-light, $white); }
.theme-color-dark {
  .bg-color-dark { @include bg-style($dark, $white); }
  .mat-ink-bar { background-color: $dark !important}
  .primary-color-full { @include bg-style($dark, $white); }
  .primary-color-text { @include text-style($dark, $white); }
  .primary-color-border { @include border-style($dark, $white); }
}

.theme-color-primary{
  .bg-color-primary { @include bg-style(var(--primary-color), $white); }
  .mat-ink-bar { background-color: var(--primary-color) !important}
  .primary-color-full { @include bg-style(var(--primary-color), $white); }
  .primary-color-text { @include text-style(var(--primary-color), $white); }
  .primary-color-border { @include border-style(var(--primary-color), $white); }
}

.theme-success{
  .bg-color-success { @include bg-style($brand-success, $white); }
  .mat-ink-bar { background-color: $brand-success !important}
  .primary-color-full {@include bg-style($brand-success, $white);}
  .primary-color-text {@include text-style($brand-success, $white);}
  .primary-color-border {@include border-style($brand-success, $white);}
}

.theme-color-info {
  .bg-color-info { @include bg-style($brand-info, $white); }
  .mat-ink-bar { background-color: $brand-info !important}
  .primary-color-full { @include bg-style($brand-info, $white); }
  .primary-color-text { @include text-style($brand-info, $white); }
  .primary-color-border { @include border-style($brand-info, $white); }
}

.bg-color-info-alt { @include bg-style($brand-info-alt, $white); }

.theme-color-warning {
  .bg-color-warning { @include bg-style($brand-warning, $body-color); }
  .mat-ink-bar { background-color: $brand-warning !important}
  .primary-color-full { @include bg-style($brand-warning, $body-color); }
  .primary-color-text { @include text-style($brand-warning, $body-color); }
  .primary-color-text { @include border-style($brand-warning, $body-color); }
}

.theme-color-danger {
  .bg-color-danger { @include bg-style($brand-danger, $white); }
  .mat-ink-bar { background-color: $brand-danger !important}
  .primary-color-full { @include bg-style($brand-danger, $white); }
  .primary-color-text { @include text-style($brand-danger, $white); }
  .primary-color-border { @include border-style($brand-danger, $white); }
}
