ckeditor.ckeditor-flex {
  .cke {
    border: 0 !important;
    height: 100% !important;

    .cke_inner {
      height: 100%;
      display: flex;
      flex-direction: column;

      .cke_contents {
        flex: 1;
      }

      .cke_bottom {
        display: none;
      }
    }
  }
}
