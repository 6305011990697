

// flip scroll (Pure CSS), thanks to http://elvery.net/demo/responsive-tables/#flip-scroll
@media only screen and (max-width: 800px) {
  .table-flip-scroll {
    .cf:after { visibility: hidden; display: block; font-size: 0; content: " "; clear: both; height: 0; }
    * html .cf { zoom: 1; }
    *:first-child+html .cf { zoom: 1; }
    table { width: 100%; border-collapse: collapse; border-spacing: 0; }

    th,
    td { margin: 0; vertical-align: top; }
    th { text-align: left; }
    table { display: block; position: relative; width: 100%; }
    thead { display: block; float: left; }
    tbody { display: block; width: auto; position: relative; overflow-x: auto; white-space: nowrap; }
    thead tr { display: block; }
    .table > thead > tr > th:first-child { // add to Bootstrap style
      border-top: 1px solid #ddd;
    }
    th { display: block; text-align: right; }
    tbody tr { display: inline-block; vertical-align: top; }
    td { display: block; min-height: 1.25em; text-align: left; }


    /* sort out borders */

    th { border-bottom: 0; border-left: 0; }
    td { border-left: 0; border-right: 0; border-bottom: 0; }
    tbody tr { border-left: 1px solid #babcbf; }
    th:last-child,
    td:last-child { border-bottom: 1px solid #babcbf; }

    // overrides md-data-table
    .mdl-data-table tbody tr {
      height: auto;
    }
  }
}
