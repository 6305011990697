#scheduler-settings,
#show-surveys-page,
#virtual-care-settings,
*[id^='navigation-parent-'] {
  position: relative;
  margin: 0 !important;
  padding: 0 !important;
  height: calc(100vh - 50px) !important;
  overflow: hidden;

  .main-navigation-container {
    overflow-y: auto;
  }
}


.card-content {
  text-overflow: ellipsis;
}

.full-height {
  height: 100%;
}

.span1.primary-navbar {
  background: #E1E7EB;
  color: #CCC;
  text-align: center;

  li {
    height: 86px;
  }
}

ul li:hover {
  color: black;
}

ul:hover {
  cursor: pointer;
}

dir,
menu,
ul {
  display: block;
  list-style-type: disc;
  -webkit-margin-before: 1em;
  -webkit-margin-after: 1em;
  -webkit-margin-start: 0;
  -webkit-margin-end: 0;
  -webkit-padding-start: 40px;
}

li {
  display: list-item;
  text-align: -webkit-match-parent;
}

.span1.primary-navbar ul .active:first-of-type a {
  background-color: #f1f5f7;
  border-top: none;
  color: #495861 !important;
  border-radius: 0;
  width: 103px;
}

.span1.primary-navbar ul .active a {
  border-right: 3px solid #F1F5F7;
  background-color: #f1f5f7 !important;
  color: #495861 !important;
  border-radius: 0;
  width: 103px;
}

.span1.primary-navbar ul li a {
  font-family: inherit;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  padding: 10px;
  text-align: center;
}

.nav-icon-fsize {
  font-size: 36px !important;
  padding: 6px !important;
  margin-top: 8px;
}

[class*=" setmore-icon-"],
[class^="setmore-icon-"] {
  font-family: 'icomoon';
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

li {
  display: list-item;
  text-align: -webkit-match-parent;
}

.nav-item-selected {
  background-color: #ddd !important;
}
.nav {
  display:block !important;
}
.button-item-selected {
  background-color: #fff !important;
}

.nav-item-not-selected {
  color: #8c8f92;
  text-decoration: none !important;
}

.nav-item-not-selected:hover {
  color: #000 !important;
  background-color: #ccc !important;
}

.horizontal-nav {
  position: fixed;
  bottom: 0;
  width: 100%;
  list-style: none;
  background-color: #f1f1f7;
  text-align: center;
  min-height: 30px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  z-index: 2000000;

  a {
    padding-bottom: 0;
  }

  ul {
    display: flex !important;
    flex: 1;
  }

  li {
    display: inline !important;
    flex: 1;
    line-height: 14px;
  }
}

.tab-content {
  background-color: #f1f5f7;
}

.mobile-nav-text {
  font-size: 8px;
}

.background-white {
  background-color: #fff;
}

.basic-button {
  background-color: #fff;
  color: #000;
}

.stacked-list {
  list-style: none;

  li {
    min-height: 90px;
  }
}

.full-width {
  width: 100% !important;
}

.service-color-holder {
  width: 5px;
  background: #0367B4;
  border-radius: 5px;
  margin: 5px 0;
}

.service-list-image {
  margin-right: 10px;
  object-fit: cover;
  width: 80px;
  height: 80px;
  float: left;
}
@media screen and (max-width: 1400px) {
  .service-list-image {
    width: 90px;
  }
}

.service-header {
  font-size: 18px;
  color: #455862;
  font-weight: 400;
  margin-bottom: 0;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 60%;
  float: left;
  margin-top: 0;
}

.service-sample-detail {
  font-size: 13px;
  color: #455862;
  font-weight: 300;
  display: block;
  float: left;
}

.service-sample-description {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-size: 12px;
  color: #ccc;
  font-weight: 300;
  width: 30%;
  float: left;
}

.padding-left-10 {
  padding-left: 10px;
}

.max75vw {
  max-width: 75vw;
}

.location-item {
  border-bottom: 1px solid #ccc;
  border-top: 1px solid #ccc;
  background-color: inherit;
  height: 70px;

  span {
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 100%;
    overflow: hidden;
  }
}

.goto-item {
  display: none;
}

.location-item:hover {
  .goto-item {
    display: block;
  }

  .service-drag-image {
    visibility: visible;
  }
}

.location-item-selected {
  background-color: #fff;

  .goto-item {
    display: block;
  }
}

.center-vertical {
  margin: auto;
}

#staffimageupload {
  margin-bottom: 0;
  /* height: 30px; */
}

.staff-image {
  border: 1px solid #ccc;
  width: 90px;
  height: 90px;
  float: right;
}

.staff_delete_camera {
  top: -2px;
  right: -12px;
}

.delete_camera {
  position: absolute;
  cursor: pointer;
  border-radius: 90px;
  background: #FFF;
  display: none;
  top: -13px;
  right: -10px;
  color: #A70000;
  width: 24px;
  height: 24px;
}

.class-list-details-holder:first-child li input[type="text"],
.service-list-details-holder:first-child li input[type="text"],
.staff-details-list li:first-child input[type="text"] {
  width: 51%;
  margin-top: 5px;
  height: 40px !important;
  font-size: 25px !important;
  font-weight: 600 !important;
}

.details-list-item textarea {
  width: 51%;
}

.details-list-item {
  min-height: auto !important;
  height: auto !important;
  display: flex;
}

.booking-polices-list li input,
.booking-polices-list li textarea,
.booking-polices-list li textarea,
.class-details-list-secondary li input[type="text"],
.class-list-details-holder li input[type="text"],
.class-list-details-holder li textarea,
.configure_company_details li input[type="text"],
.configure_company_details li textarea,
.customer-list-wrapper .customer-search,
.embed-page-list li input[type="text"],
.embed-page-list li textarea,
.notification-list li input[type="text"],
.notification-list li textarea,
.payment-list li input[type="password"],
.payment-list li input[type="text"],
.service-details-list-secondary li input[type="text"],
.service-list-details-holder li input[type="text"],
.service-list-details-holder li textarea,
.staff-details-list li input[type="text"],
.staff-details-list li textarea,
.staff-details-list-secondary li input[type="text"],
input[type="text"].category-name-box {
  border: 1px solid #fff;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0);
  -moz-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0);
  font-size: 14px;
}

.class-list-details-holder li input[type="text"],
.service-list-details-holder li input[type="text"],
.staff-details-container .tab-content .tab-pane .row-fluid .span10 h3:first-child,
.staff-details-list li input[type="text"] {
  font-size: 22px !important;
  color: #455862;
  font-weight: 400;
  margin: 0;
}

.yellow_hover:hover {
  background-color: #E1E7EB !important;
  cursor: pointer !important;
  cursor: hand !important;
  box-shadow: 0 0 3px #fff !important;
  /* border: 1px solid #fff !important; */
}

input.blue_focus:focus,
textarea.blue_focus:focus {
  background: none !important;
  padding: 4px 5px !important;
  cursor: auto !important;
  margin-bottom: 5px;
  border-color: rgba(39, 195, 187, 0.8) !important;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(39, 195, 187, 0.6) !important;
  -moz-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(39, 195, 187, 0.6) !important;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(39, 195, 187, 0.6) !important;
  border-radius: 5px;
}

.new_item_field {
  width: 100% !important;
}

.new_item_field:hover {
  width: 100% !important;
  background-color: #E1E7EB !important;
  cursor: pointer !important;
  cursor: hand !important;
  box-shadow: 0 0 3px #fff !important;
}

.new_item_field:focus {
  background: none !important;
  padding: 4px 5px !important;
  cursor: auto !important;
  margin-bottom: 5px;
  border-color: rgba(39, 195, 187, 0.8) !important;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(39, 195, 187, 0.6) !important;
  -moz-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(39, 195, 187, 0.6) !important;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(39, 195, 187, 0.6) !important;
  border-radius: 5px;
}

.t_2 {
  font-size: 18px !important;
  height: 40px !important;
}

.grey_hover {
  background-color: #E1E7EB !important;
  cursor: pointer !important;
  cursor: hand !important;
  box-shadow: 0 0 3px #fff !important;
}

.hide {
  display: none !important;
}

.class-details-list-secondary li label,
.service-details-list-secondary li label,
.staff-details-list-secondary li label,
.staff-details-list-secondary li label {
  font-size: 14px;
  color: #999;
  text-align: right;
  padding-top: 5px;
  padding-right: 15px;
  cursor: default;
  margin-top: 2px;
}

.staff-details-list-secondary li input[type="text"] {
  width: 41.8%;
  height: 30px;
  margin-bottom: 2px;
  // margin-left: 2.564102564102564%;
}

.staff-divider-for-details {
  height: 1px;
  background: #CCC;
  margin-top: 15px;
  margin-bottom: 15px;
}

.solid-bottom-border {
  border-bottom: 1px #ccc solid;
}

.selected-appointment-type {
  background-color: #dff6f5;

  i {
    color: #0FB5BB;
  }
}

mat-slide-toggle {
  input {
    width: auto;
    position: absolute;
  }
}
@media screen and (max-width: 600px) {
  .no-margin-no-padding {
    margin: 0;
    padding: 0;
  }

  .full-width-mobile {
    width: 100% !important;
  }
}

.overflow-scroll {
  overflow-y: auto;
}

.new-gray-btn {
  background: #F1F5F7;
  border-color: #F1F5F7;
  font-size: 14px !important;
  line-height: 32px !important;
  margin-top: 5px;
  padding: 1px 20px !important;
  text-align: center;
  color: #252C32;
  border-style: solid;
  border-width: 1px;
  height: 34px;
  border-radius: 3px !important;
  cursor: pointer;
  border: solid 1px var(--primary-color) !important;
  display: -webkit-inline-box;
  width: 100% !important;
  margin-top: 0;
  height: 36px !important;
}

.tab-title {
  font-size: 23px;
}

.center-fully {
  margin: auto;
}

.side-list {
  margin-top: 15px;
  overflow-y: auto;
  /* margin-left: -20px; */
}

.second-menu-list {
  // padding: 0px 30px;
  width: 100%;

  a {
    color: #c1c8cd;
    border-radius: 5px;
  }

  a:hover {
    background: #E1E7EB;
    color: #495861 !important;
  }

  i,
  span {
    font-size: 14px;
  }

  ul {
    margin: auto;
  }

  li {
    height: auto;
    min-width: 250px;
  }

  .hidden-chevron {
    display: none;
    font-size: 36px;
    margin-top: -5px;
  }

  .active-nav {
    // background-color: #fff;
    color: #000;
    font-size: 20px;
  }

  .show-chevron {
    display: block;
  }
}

.notifications-tab {
  width: 90% !important;
  margin: auto !important;

  .detail-navbar {
    margin-left: 28px;
    border-bottom: 1px solid #CCC;
    padding-right: 0;
    /* width: 100%; */
    padding-left: 0;
    margin-top: 10px;
  }

  .border-top {
    padding-top: 30px;
  }

  .border-bottom {
    padding-bottom: 30px;
    border-bottom: 1px solid #CCC;
  }
}
@media screen and (max-width: 750px) {
  .notifications-tab {
    width: 100% !important;
  }

}

@media screen and (max-width: 768px) {

  .main-navigation-container {
    margin-bottom: 73px;
  }
}

.tablet-menu {
  display: none;
}
@media screen and (max-width: 768px) {
  .tablet-menu {
    display: inherit;
  }
}
@media screen and (min-width: 768px) {
  .padding-desktop-5 {
    padding: 3em !important;
  }
}

mat-sidenav {
  width: 300px;
}

.mat-drawer {
  background-color: #f1f5f7;
}

.search-bar-nav {
  position: relative;
  padding-top: 5px;

  .material-icons {
    position: absolute;
    left: 15px;
    top: 13px;
    z-index: 999;
  }

  input {
    font: 16px/1.875 "Avenir Next W01", "Avenir Next", "Helvetica Neue", Helvetica, sans-serif;
    height: 40px;
    border: 1px solid #f1f1f7;
    border-radius: 4px;
    padding-left: 50px;
  }
}

.nav-button {
  height: 50px;
}
/* Structure */
.settings-container {
  display: flex;
  flex-direction: column;
  min-width: 300px;
}

.settings-header {
  min-height: 64px;
  display: flex;
  align-items: baseline;
  padding: 8px 24px 0;
  font-size: 20px;
  justify-content: space-between;
}

.mat-form-field {
  font-size: 14px;
  flex-grow: 1;
}

.mat-table {
  overflow: auto;
  max-height: 500px;
}

.divider-grey {
  border-top: 1px solid #f1f1f1;
}

.service-drag-image {
  background: url('/assets/images/drag-drop-bg.png') repeat-y;
  // height: 60px;
  width: 15px;
  visibility: hidden;
  cursor: move;
  margin: 5px 0;
  // display: none;
}

.initial-start-page {
  background-color: #eaf5fb;
}
