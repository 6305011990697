// theme
.app-header {
  padding: 0;
  border: 0; // no border, otherwise other elements' height can only be ($l_header_height -1)
  text-align: center;

  .app-header-inner {
    height: $l_header_height;
    box-shadow: $header_box_shadow;
  }
  @media only screen and (min-width: $l_screen_md_min) {
    text-align: inherit;
  }

  @media only screen and (max-width: $l_screen_md_min) {

    .app-header-inner {
      box-shadow: none;
    }
  }

  &.bg-transparent {
    background-color: transparent !important;
  }

  .brand {
    display: inline-block;
    text-align: center;
    float: left; // becasue of alignment problem with md-button in .top-nav-left

    h2 {
      font-size: 30px;
      margin: 0;
      line-height: $l_header_height;
    }
  }

  .header-icon {
    display: inline-block;
    height: $l_header_height;
    padding: 0 14px;
    @media only screen and (min-width: $l_screen_md_min) {
      padding-left: 18px;
      padding-right: 18px;
    }
    .material-icons {
      font-size: 24px;
    }
  }
}

.top-nav-left,
.top-nav-right {
  > ul > .list-inline-item {
    margin: 0;
    padding: 0;
  }
}

.top-nav-left {
  display: inline-block;
  > ul {
    display: inline;
    margin: 0;
  }
}

.top-nav-right {
  display: inline-block;
  font-size: 16px;
  line-height: $header_line_height;
  float: right;

  ul {
    margin: 0;
  }


  //
  li {
    height: $l_header_height;
    float: left;
  }

  a {
    &:hover,
    &:focus {
      text-decoration: none;
    }
  }
}
