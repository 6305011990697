

:focus {
  outline: none !important;
}


// default-contrast of Accent and Warm should be white text instead of dark, so manually overrides
button,
a {
  &[md-fab], &[md-mini-fab], &[md-raised-button] {
    text-decoration: none;
  }

  &[md-raised-button].mat-warn, &[md-fab].mat-warn, &[md-mini-fab].mat-warn,
  &[md-raised-button].mat-accent, &[md-fab].mat-accent, &[md-mini-fab].mat-accent {
    color: $white;
  }
  &[md-fab], &[md-mini-fab] {
    color: $white;
  }

  &.btn-lg {
    // line-height: ensure even-numbered height of button next to large input
    @include button-size($btn-padding-y-lg, $btn-padding-x-lg, $font-size-lg, $btn-border-radius-lg);
  }
  &.btn-sm {
    // line-height: ensure proper height of button next to small input
    @include button-size($btn-padding-y-sm, $btn-padding-x-sm, $font-size-sm, $btn-border-radius-sm);
  }
}

// header dropdown
.md-menu-content {
  a[md-menu-item] {
    text-decoration: none;
    &:hover,
    &:focus {
      color: $body-color;
    }
  }
}

[md-tab-nav-bar], .md-tab-header {
  border-bottom: 1px solid rgba(0,0,0,.05);
}

[mdInput] {
  width: 100%;
}

md-input-container {
  &.md-icon-left {
    position: relative;
    padding-left: 36px;

    .mat-input-wrapper {
      position: relative;
    }

    .mat-input-infix > md-icon {
      position: absolute;
      top: 0;
      left: -32px;
      right: auto;
    }
  }
}
.mat-form-field .mat-icon.material-icons {
  font-size: 24px;
}

.md-radio-group-spacing {
  md-radio-button {
    margin-right: 20px;
    &:last-child {
      margin-right: 0;
    }
  }
}
