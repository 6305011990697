.page-support {
  md-list-item.accordion {
    &.open {
      .mat-list-item-content {
        > .icon-has-accordion {
          transform: rotate(-180deg);
        }
      }
    }
  }

  md-list-item.accordion:hover {
    background-color: #E2E2E2;
  }

  md-list-item.open {
    background-color: #E2E2E2;
  }

  .icon-has-accordion {
    position: absolute;
    top: 12px;
    right: 15px;
    font-size: 18px;
    line-height: 1;
    color: #777;
    transition: transform .3s ease-in-out;
  }

  .answer {
    padding-left: 40px;
  }
}
