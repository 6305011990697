/* You can add global styles to this file, and also import other style files */
@import 'insig-health-colors';
@import 'insig-health-aria';

.doctor-card-container .mat-dialog-container {
  padding: 24px !important;
}

.mdc-list-item__primary-text {
  overflow: hidden !important;
  white-space: nowrap !important;
  text-overflow: ellipsis !important;
}

.tali__defaultFloatingBtn {
  top: unset !important;
  bottom: 0 !important;
}

// google places autocomplete
.pac-container {
  z-index: 999999 !important;
}

.card, .card-panel {
  box-shadow: 0 2px 2px #00000024, 0 3px 1px -2px #0003, 0 1px 5px #0000001f;
}

@media(max-width: 576px) {
  mat-tab-group[sm-vertical] {
    .mat-mdc-tab-header {
      position: sticky;
      top: 0;
      z-index: 9999;
      background-color: white;
    }
  
    .mat-mdc-tab-labels {
      flex-direction: column !important;
    }
  
    .mat-mdc-tab-body-wrapper {
      width: 100%;
    }
  }
}
