@use "sass:math";
@use "sass:list";
@import "../bootstrap/mixins/_breakpoints.scss";

.mat-dialog-actions {
  min-height: auto !important;
  padding: 0 !important;
  margin-bottom: 0 !important;
}

.mat-dialog-container {
  border-radius: 10px !important;
}

.primary-color-full {
  background-color: var(--primary-color) !important;
  color: white !important;
}

.position-absolute {
  position: absolute;
}

.pillwayDialogLogo {
  max-width: 100%;
  max-height: 80px;
}

.ngxFileDropZone {
  min-width: 200px;
  min-height: 50px;
  border: 2px dashed #333;
  color: inherit;
  padding: 7px;
}

.tiaDelivery {
  color: #009fde;
  font-weight: bold;
  font-size: 23px;
}

.tiaDelivery2 {
  color: #009fde;
  font-weight: bold;
  font-size: 18px;
}

.bg-dark {
  background: black;
}

.bg-transparent {
  background: transparent;
}

.text-light {
  color: white;
}

.text-dark {
  color: #343a40 !important;
}

.ngxFileDropContent {
  display: flex;
  align-items: center;
  justify-content: center;
}

.rounded-0-important {
  border-radius: 0 !important;
}

.App-video-container .video-container video {
  height: 100%;
  max-width: 100%;
}

.App-video-container .video-container.small video {
  height: auto;
  width: auto;
  max-height: 100%;
}

.mat-dialog-content {
  margin: 0 !important;
  padding: 0 !important;
}

.cursor-pointer {
  cursor: pointer;
}

.position-relative {
  position: relative;
}

.editor-actions {
  bottom: initial !important;
  z-index: 1000 !important;
  width: initial !important;
}

.m-0-children * {
  margin: 0 !important;
}

.p-0-children * {
  padding: 0 !important;
}

// Make sure mat-menu styles are loaded globally
.mat-menu-panel {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  min-width: 112px;
  max-width: 280px;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  max-height: calc(100vh - 48px);
  border-radius: 2px;
  outline: 0;
}
.mat-menu-panel:not([class*='mat-elevation-z']) {
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
}
.mat-menu-panel.mat-menu-after.mat-menu-below {
  transform-origin: left top;
}
.mat-menu-panel.mat-menu-after.mat-menu-above {
  transform-origin: left bottom;
}
.mat-menu-panel.mat-menu-before.mat-menu-below {
  transform-origin: right top;
}
.mat-menu-panel.mat-menu-before.mat-menu-above {
  transform-origin: right bottom;
}
[dir='rtl'] .mat-menu-panel.mat-menu-after.mat-menu-below {
  transform-origin: right top;
}
[dir='rtl'] .mat-menu-panel.mat-menu-after.mat-menu-above {
  transform-origin: right bottom;
}
[dir='rtl'] .mat-menu-panel.mat-menu-before.mat-menu-below {
  transform-origin: left top;
}
[dir='rtl'] .mat-menu-panel.mat-menu-before.mat-menu-above {
  transform-origin: left bottom;
}
.mat-menu-panel.ng-animating {
  pointer-events: none;
}
@media screen and (-ms-high-contrast: active) {
  .mat-menu-panel {
    outline: solid 1px;
  }
}
.mat-menu-content {
  padding-top: 8px;
  padding-bottom: 8px;
}
.mat-menu-item {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
  outline: 0;
  border: none;
  -webkit-tap-highlight-color: transparent;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  line-height: 48px;
  height: 48px;
  padding: 0 16px;
  text-align: left;
  text-decoration: none;
  position: relative;
}
.mat-menu-item[disabled] {
  cursor: default;
}
[dir='rtl'] .mat-menu-item {
  text-align: right;
}
.mat-menu-item .mat-icon {
  margin-right: 16px;
  vertical-align: middle;
}
[dir='rtl'] .mat-menu-item .mat-icon {
  margin-left: 16px;
  margin-right: 0;
}
.mat-menu-item-submenu-trigger {
  padding-right: 32px;
}
.mat-menu-item-submenu-trigger::after {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 5px 0 5px 5px;
  border-color: transparent transparent transparent currentColor;
  content: '';
  display: inline-block;
  position: absolute;
  top: 50%;
  right: 16px;
  transform: translateY(-50%);
}
[dir='rtl'] .mat-menu-item-submenu-trigger {
  padding-right: 16px;
  padding-left: 32px;
}
[dir='rtl'] .mat-menu-item-submenu-trigger::after {
  right: auto;
  left: 16px;
  transform: rotateY(180deg) translateY(-50%);
}
button.mat-menu-item {
  width: 100%;
}
.mat-menu-ripple {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  pointer-events: none;
}

.modal-backdrop {
  background-color: rgba(0, 0, 0, 0.5);
}

// @media (min-width: 991px) {
//   .float-right-md {
//     float:right;
//   }
// }

.hr-text {
  border-top: 0 !important;
  line-height: 1em;
  position: relative;
  outline: 0;
  border: 0;
  color: black;
  text-align: center;
  height: 1.5em;
  opacity: 0.5;
  &:before {
    content: '';
    // use the linear-gradient for the fading effect
    // use a solid background color for a solid bar
    background: linear-gradient(to right, transparent, #818078, transparent);
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    height: 1px;
  }
  &:after {
    content: attr(data-content);
    position: relative;
    display: inline-block;
    color: black;

    padding: 0 0.5em;
    line-height: 1.5em;
    // this is really the only tricky part, you need to specify the background color of the container element...
    color: #818078;
    background-color: #fcfcfa;
  }
}

.modal-body {
  max-height: calc(80vh - 200px);
  overflow-y: auto;
}

.mat-divider {
  border-top-width: 0.11em !important;
}

.fontWeight350 {
  font-weight: 350;
}

.lineHeight1o4em {
  line-height: 1.4em;
}

.bkg-white {
  background-color: #fff;
}

.m-hover-transition {
  // margin-left: 1em;
  margin-right: 1em;
  transition: margin-left 0.5s ease-in-out, margin-right 0.5s ease-in-out;
}

.m-hover-transition:hover {
  margin-left: 0.5em;
  margin-right: 0.5em;
}

.cke_screen_reader_only {
  /* ckeditor for screen readers; currently causes styling issues so we are disabling it */
  display: none;
}

.pointer-events-none {
  pointer-events: none;
}

.d-initial {
  display: initial;
}

.overflow-x-auto {
  overflow-x: auto;
}

.overflow-x-hidden {
  overflow-x: hidden;
}

.overflow-y-auto {
  overflow-y: auto;
}

.overflow-hidden {
  overflow: hidden;
}

.text-black {
  color: #000 !important;
}

.mw-100 {
  max-width: 100;
}

.w-100vw {
  width: 100vw;
}

h4 {
  // Fix for angular material bug
  line-height: 1.2;
}

.flex {
  flex: 1;
}

.flex-even {
  flex: 1 1 0;
}

.flex-static {
  flex: 0 0 auto;
}

.centeringMargin {
  margin: 0 auto;
}

.mat-form-field-underline {
  height: 0.11em !important;
}

.mat-select-underline {
  height: 0.11em !important;
}

.goog-te-banner {
  display: none !important;
}

iframe.goog-te-banner-frame {
  display: none !important;
}

.goog-te-banner-frame {
  display: none !important;
}

.goog-tooltip {
  display: none !important;
}

.goog-logo-link {
  display: none !important;
}

.goog-te-gadget {
  color: transparent !important;
  margin-top: 0.5cm;
}

.mat-input-placeholder {
  color: rgba(0, 0, 0, 0.7);
}

.goog-text-highlight {
  background-color: rgb(256, 256, 256) !important;
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important;
  box-sizing: none !important;
  -webkit-box-sizing: none !important;
  -moz-box-sizing: none !important;
}
// rgba(#000, .035) // #eee
$image_path: 'insig-app/assets/images' !default;
$image_pathdemo: 'insig-app/assets/images-demo' !default;
$skin: var(--primary-color);
$skin-light: #42a5f5 !default; // Blue 400
$dark: var(--secondary-color) !default; // darken Blue 100 > #343E46
$dark-light: lighten($dark, 5%) !default;
$bright: #fafafa !default;
$white: var(--text-selected) !default;
$gray-light2: #999 !default;
$page-bg: #f5f5f5 !default; // background color of .app-page-container
$state-default-bg: rgba(0, 0, 0, 0.1) !default; // also: pagination bg
$state-default-border: darken(adjust-hue($state-default-bg, -10), 5%) !default;
$state-default-border-light: #f3f3f3 !default;
//== Typography
$font-weight-thin: 300 !default;
$font-family-condensed: 'Roboto Condensed', 'Roboto', 'Helvetica Neue', Arial,
  sans-serif !default;
// UI
$container_fluid_maxwidth: 1400px;
// Cards
$card-padding: 20px !default;
$border-radius-card: 2px !default;
// Grid Variables
$element-top-margin: math.div($grid-gutter-width-base, 3) !default;
$element-bottom-margin: math.div($grid-gutter-width-base * 2, 3) !default;
// Tables
$theme-dark-data-table-header-color: rgba(#fff, 0.54);
// box shadow
@mixin z-depth-1 {
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
}
@mixin z-depth-1-half {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
@mixin z-depth-2 {
  box-shadow: 0 8px 17px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
@mixin z-depth-3 {
  box-shadow: 0 12px 15px 0 rgba(0, 0, 0, 0.24),
    0 17px 50px 0 rgba(0, 0, 0, 0.19);
}
@mixin z-depth-4 {
  box-shadow: 0 16px 28px 0 rgba(0, 0, 0, 0.22),
    0 25px 55px 0 rgba(0, 0, 0, 0.21);
}
@mixin z-depth-5 {
  box-shadow: 0 27px 24px 0 rgba(0, 0, 0, 0.2),
    0 40px 77px 0 rgba(0, 0, 0, 0.22);
}

.min-height-0 {
  min-height: 0 !important;
}

.min-width-0 {
  min-width: 0;
}

.flex-grow-1 {
  flex-grow: 1;
}

.w-fit-content {
  width: fit-content;
}

.min-vw-50 {
  min-width: 50vw;
}

@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    .w-#{$breakpoint}-auto {
      width: auto !important;
    }
  }
}

.bg-white {
  background-color: white !important;
}

@include media-breakpoint-up(md) {
  .d-md-grid {
    display: grid !important;
  }

  .w-md-fit-content {
    width: fit-content !important;
  }
}

.grid-cols-3 {
  grid-template-columns: repeat(3, minmax(0, 1fr));
}

.h-fit-content {
  height: fit-content !important;
}

.overflow-auto {
  overflow: auto;
}

.min-dvh-35 {
  min-height: 35dvh;
}

.min-h-inherit {
  min-height: inherit;
}

.rounded-lg {
  border-radius: .3rem !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-2 {
  flex-shrink: 2 !important;
}

.flex-grow-2 {
  flex-grow: 2 !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

$ins-breakpoints: "sm", "md", "lg", "xl";
$base-spacer: 1rem;
$ins-spacers: (
  0: 0,
  1: $base-spacer * .25,
  2: $base-spacer * .5,
  3: $base-spacer,
  4: $base-spacer * 1.5,
  5: $base-spacer * 3,
);
@each $size, $spacer in $ins-spacers {
  .gap-#{$size} {
    gap: $spacer;
    .flex-even {
      flex: 1;
    }
    @for $i from 1 through 8 {
      $number-of-gaps: calc(#{$i} - 1);
      $total-gap-width: calc(#{$spacer} * #{$number-of-gaps});
      .flex-even:first-child:nth-last-child( #{$i} ),
      .flex-even:first-child:nth-last-child( #{$i} ) ~ .flex-even {
        min-width: calc((100% - #{$total-gap-width}) / #{$i});
      }
    }
  }
  @each $breakpoint in $ins-breakpoints {
    @include media-breakpoint-up($breakpoint) {
      .gap-#{$breakpoint}-#{$size} {
        gap: $spacer;
      }
    }
  }
}

.text-decoration-none {
  text-decoration: none !important;
}
