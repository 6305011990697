.gap-0 {
  gap: 0;
}

.gap-1 {
  gap: 0.25rem;
}

.gap-2 {
  gap: 0.5rem;
}

.gap-3 {
  gap: 1rem;
}

.gap-4 {
  gap: 1.5rem;
}

.gap-5 {
  gap: 3rem;
}

.flex-fill {
  flex: 1 1 auto;
}

.mat-dialog-actions {
  min-height: auto !important;
  padding: 0 !important;
  margin-bottom: 0 !important;
}

.mat-dialog-container {
  border-radius: 10px !important;
}

.primary-color-full {
  background-color: var(--primary-color) !important;
  color: white !important;
}

.position-absolute {
  position: absolute;
}

.pillwayDialogLogo {
  max-width: 100%;
  max-height: 80px;
}

.ngxFileDropZone {
  min-width: 200px;
  min-height: 50px;
  border: 2px dashed #333;
  color: inherit;
  padding: 7px;
}

.tiaDelivery {
  color: #009fde;
  font-weight: bold;
  font-size: 23px;
}

.tiaDelivery2 {
  color: #009fde;
  font-weight: bold;
  font-size: 18px;
}

.bg-dark {
  background: black;
}

.bg-transparent {
  background: transparent;
}

.text-light {
  color: white;
}

.text-dark {
  color: #343a40 !important;
}

.ngxFileDropContent {
  display: flex;
  align-items: center;
  justify-content: center;
}

.rounded-0-important {
  border-radius: 0 !important;
}

.App-video-container .video-container video {
  height: 100%;
  max-width: 100%;
}

.App-video-container .video-container.small video {
  height: auto;
  width: auto;
  max-height: 100%;
}

.mat-dialog-content {
  margin: 0 !important;
  padding: 0 !important;
}

.cursor-pointer {
  cursor: pointer;
}

.position-relative {
  position: relative;
}

.editor-actions {
  bottom: initial !important;
  z-index: 1000 !important;
  width: initial !important;
}

.m-0-children * {
  margin: 0 !important;
}

.p-0-children * {
  padding: 0 !important;
}

.mat-menu-panel {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  min-width: 112px;
  max-width: 280px;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  max-height: calc(100vh - 48px);
  border-radius: 2px;
  outline: 0;
}

.mat-menu-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}

.mat-menu-panel.mat-menu-after.mat-menu-below {
  transform-origin: left top;
}

.mat-menu-panel.mat-menu-after.mat-menu-above {
  transform-origin: left bottom;
}

.mat-menu-panel.mat-menu-before.mat-menu-below {
  transform-origin: right top;
}

.mat-menu-panel.mat-menu-before.mat-menu-above {
  transform-origin: right bottom;
}

[dir=rtl] .mat-menu-panel.mat-menu-after.mat-menu-below {
  transform-origin: right top;
}

[dir=rtl] .mat-menu-panel.mat-menu-after.mat-menu-above {
  transform-origin: right bottom;
}

[dir=rtl] .mat-menu-panel.mat-menu-before.mat-menu-below {
  transform-origin: left top;
}

[dir=rtl] .mat-menu-panel.mat-menu-before.mat-menu-above {
  transform-origin: left bottom;
}

.mat-menu-panel.ng-animating {
  pointer-events: none;
}

@media screen and (-ms-high-contrast: active) {
  .mat-menu-panel {
    outline: solid 1px;
  }
}
.mat-menu-content {
  padding-top: 8px;
  padding-bottom: 8px;
}

.mat-menu-item {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
  outline: 0;
  border: none;
  -webkit-tap-highlight-color: transparent;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  line-height: 48px;
  height: 48px;
  padding: 0 16px;
  text-align: left;
  text-decoration: none;
  position: relative;
}

.mat-menu-item[disabled] {
  cursor: default;
}

[dir=rtl] .mat-menu-item {
  text-align: right;
}

.mat-menu-item .mat-icon {
  margin-right: 16px;
  vertical-align: middle;
}

[dir=rtl] .mat-menu-item .mat-icon {
  margin-left: 16px;
  margin-right: 0;
}

.mat-menu-item-submenu-trigger {
  padding-right: 32px;
}

.mat-menu-item-submenu-trigger::after {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 5px 0 5px 5px;
  border-color: transparent transparent transparent currentColor;
  content: "";
  display: inline-block;
  position: absolute;
  top: 50%;
  right: 16px;
  transform: translateY(-50%);
}

[dir=rtl] .mat-menu-item-submenu-trigger {
  padding-right: 16px;
  padding-left: 32px;
}

[dir=rtl] .mat-menu-item-submenu-trigger::after {
  right: auto;
  left: 16px;
  transform: rotateY(180deg) translateY(-50%);
}

button.mat-menu-item {
  width: 100%;
}

.mat-menu-ripple {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  pointer-events: none;
}

.modal-backdrop {
  background-color: rgba(0, 0, 0, 0.5);
}

.hr-text {
  border-top: 0 !important;
  line-height: 1em;
  position: relative;
  outline: 0;
  border: 0;
  color: black;
  text-align: center;
  height: 1.5em;
  opacity: 0.5;
}
.hr-text:before {
  content: "";
  background: linear-gradient(to right, transparent, #818078, transparent);
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  height: 1px;
}
.hr-text:after {
  content: attr(data-content);
  position: relative;
  display: inline-block;
  color: black;
  padding: 0 0.5em;
  line-height: 1.5em;
  color: #818078;
  background-color: #fcfcfa;
}

.modal-body {
  max-height: calc(80vh - 200px);
  overflow-y: auto;
}

.mat-divider {
  border-top-width: 0.11em !important;
}

.fontWeight350 {
  font-weight: 350;
}

.lineHeight1o4em {
  line-height: 1.4em;
}

.bkg-white {
  background-color: #fff;
}

.m-hover-transition {
  margin-right: 1em;
  transition: margin-left 0.5s ease-in-out, margin-right 0.5s ease-in-out;
}

.m-hover-transition:hover {
  margin-left: 0.5em;
  margin-right: 0.5em;
}

.cke_screen_reader_only {
  /* ckeditor for screen readers; currently causes styling issues so we are disabling it */
  display: none;
}

.pointer-events-none {
  pointer-events: none;
}

.d-initial {
  display: initial;
}

.overflow-x-auto {
  overflow-x: auto;
}

.overflow-x-hidden {
  overflow-x: hidden;
}

.overflow-y-auto {
  overflow-y: auto;
}

.overflow-hidden {
  overflow: hidden;
}

.text-black {
  color: #000 !important;
}

.mw-100 {
  max-width: 100;
}

.w-100vw {
  width: 100vw;
}

h4 {
  line-height: 1.2;
}

.flex {
  flex: 1;
}

.flex-even {
  flex: 1 1 0;
}

.flex-static {
  flex: 0 0 auto;
}

.centeringMargin {
  margin: 0 auto;
}

.mat-form-field-underline {
  height: 0.11em !important;
}

.mat-select-underline {
  height: 0.11em !important;
}

.goog-te-banner {
  display: none !important;
}

iframe.goog-te-banner-frame {
  display: none !important;
}

.goog-te-banner-frame {
  display: none !important;
}

.goog-tooltip {
  display: none !important;
}

.goog-logo-link {
  display: none !important;
}

.goog-te-gadget {
  color: transparent !important;
  margin-top: 0.5cm;
}

.mat-input-placeholder {
  color: rgba(0, 0, 0, 0.7);
}

.goog-text-highlight {
  background-color: rgb(255, 255, 255) !important;
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important;
  box-sizing: none !important;
  -webkit-box-sizing: none !important;
  -moz-box-sizing: none !important;
}

.min-height-0 {
  min-height: 0 !important;
}

.min-width-0 {
  min-width: 0;
}

.flex-grow-1 {
  flex-grow: 1;
}

.w-fit-content {
  width: fit-content;
}

.min-vw-50 {
  min-width: 50vw;
}

.w-xs-auto {
  width: auto !important;
}

@media (min-width: 576px) {
  .w-sm-auto {
    width: auto !important;
  }
}
@media (min-width: 768px) {
  .w-md-auto {
    width: auto !important;
  }
}
@media (min-width: 992px) {
  .w-lg-auto {
    width: auto !important;
  }
}
@media (min-width: 1200px) {
  .w-xl-auto {
    width: auto !important;
  }
}
.bg-white {
  background-color: white !important;
}

@media (min-width: 768px) {
  .d-md-grid {
    display: grid !important;
  }
  .w-md-fit-content {
    width: fit-content !important;
  }
}
.grid-cols-3 {
  grid-template-columns: repeat(3, minmax(0, 1fr));
}

.h-fit-content {
  height: fit-content !important;
}

.overflow-auto {
  overflow: auto;
}

.min-dvh-35 {
  min-height: 35dvh;
}

.min-h-inherit {
  min-height: inherit;
}

.rounded-lg {
  border-radius: 0.3rem !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-2 {
  flex-shrink: 2 !important;
}

.flex-grow-2 {
  flex-grow: 2 !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.gap-0 {
  gap: 0;
}
.gap-0 .flex-even {
  flex: 1;
}
.gap-0 .flex-even:first-child:nth-last-child(1),
.gap-0 .flex-even:first-child:nth-last-child(1) ~ .flex-even {
  min-width: calc((100% - calc(0 * calc(1 - 1))) / 1);
}
.gap-0 .flex-even:first-child:nth-last-child(2),
.gap-0 .flex-even:first-child:nth-last-child(2) ~ .flex-even {
  min-width: calc((100% - calc(0 * calc(2 - 1))) / 2);
}
.gap-0 .flex-even:first-child:nth-last-child(3),
.gap-0 .flex-even:first-child:nth-last-child(3) ~ .flex-even {
  min-width: calc((100% - calc(0 * calc(3 - 1))) / 3);
}
.gap-0 .flex-even:first-child:nth-last-child(4),
.gap-0 .flex-even:first-child:nth-last-child(4) ~ .flex-even {
  min-width: calc((100% - calc(0 * calc(4 - 1))) / 4);
}
.gap-0 .flex-even:first-child:nth-last-child(5),
.gap-0 .flex-even:first-child:nth-last-child(5) ~ .flex-even {
  min-width: calc((100% - calc(0 * calc(5 - 1))) / 5);
}
.gap-0 .flex-even:first-child:nth-last-child(6),
.gap-0 .flex-even:first-child:nth-last-child(6) ~ .flex-even {
  min-width: calc((100% - calc(0 * calc(6 - 1))) / 6);
}
.gap-0 .flex-even:first-child:nth-last-child(7),
.gap-0 .flex-even:first-child:nth-last-child(7) ~ .flex-even {
  min-width: calc((100% - calc(0 * calc(7 - 1))) / 7);
}
.gap-0 .flex-even:first-child:nth-last-child(8),
.gap-0 .flex-even:first-child:nth-last-child(8) ~ .flex-even {
  min-width: calc((100% - calc(0 * calc(8 - 1))) / 8);
}

@media (min-width: 576px) {
  .gap-sm-0 {
    gap: 0;
  }
}
@media (min-width: 768px) {
  .gap-md-0 {
    gap: 0;
  }
}
@media (min-width: 992px) {
  .gap-lg-0 {
    gap: 0;
  }
}
@media (min-width: 1200px) {
  .gap-xl-0 {
    gap: 0;
  }
}
.gap-1 {
  gap: 0.25rem;
}
.gap-1 .flex-even {
  flex: 1;
}
.gap-1 .flex-even:first-child:nth-last-child(1),
.gap-1 .flex-even:first-child:nth-last-child(1) ~ .flex-even {
  min-width: calc((100% - calc(0.25rem * calc(1 - 1))) / 1);
}
.gap-1 .flex-even:first-child:nth-last-child(2),
.gap-1 .flex-even:first-child:nth-last-child(2) ~ .flex-even {
  min-width: calc((100% - calc(0.25rem * calc(2 - 1))) / 2);
}
.gap-1 .flex-even:first-child:nth-last-child(3),
.gap-1 .flex-even:first-child:nth-last-child(3) ~ .flex-even {
  min-width: calc((100% - calc(0.25rem * calc(3 - 1))) / 3);
}
.gap-1 .flex-even:first-child:nth-last-child(4),
.gap-1 .flex-even:first-child:nth-last-child(4) ~ .flex-even {
  min-width: calc((100% - calc(0.25rem * calc(4 - 1))) / 4);
}
.gap-1 .flex-even:first-child:nth-last-child(5),
.gap-1 .flex-even:first-child:nth-last-child(5) ~ .flex-even {
  min-width: calc((100% - calc(0.25rem * calc(5 - 1))) / 5);
}
.gap-1 .flex-even:first-child:nth-last-child(6),
.gap-1 .flex-even:first-child:nth-last-child(6) ~ .flex-even {
  min-width: calc((100% - calc(0.25rem * calc(6 - 1))) / 6);
}
.gap-1 .flex-even:first-child:nth-last-child(7),
.gap-1 .flex-even:first-child:nth-last-child(7) ~ .flex-even {
  min-width: calc((100% - calc(0.25rem * calc(7 - 1))) / 7);
}
.gap-1 .flex-even:first-child:nth-last-child(8),
.gap-1 .flex-even:first-child:nth-last-child(8) ~ .flex-even {
  min-width: calc((100% - calc(0.25rem * calc(8 - 1))) / 8);
}

@media (min-width: 576px) {
  .gap-sm-1 {
    gap: 0.25rem;
  }
}
@media (min-width: 768px) {
  .gap-md-1 {
    gap: 0.25rem;
  }
}
@media (min-width: 992px) {
  .gap-lg-1 {
    gap: 0.25rem;
  }
}
@media (min-width: 1200px) {
  .gap-xl-1 {
    gap: 0.25rem;
  }
}
.gap-2 {
  gap: 0.5rem;
}
.gap-2 .flex-even {
  flex: 1;
}
.gap-2 .flex-even:first-child:nth-last-child(1),
.gap-2 .flex-even:first-child:nth-last-child(1) ~ .flex-even {
  min-width: calc((100% - calc(0.5rem * calc(1 - 1))) / 1);
}
.gap-2 .flex-even:first-child:nth-last-child(2),
.gap-2 .flex-even:first-child:nth-last-child(2) ~ .flex-even {
  min-width: calc((100% - calc(0.5rem * calc(2 - 1))) / 2);
}
.gap-2 .flex-even:first-child:nth-last-child(3),
.gap-2 .flex-even:first-child:nth-last-child(3) ~ .flex-even {
  min-width: calc((100% - calc(0.5rem * calc(3 - 1))) / 3);
}
.gap-2 .flex-even:first-child:nth-last-child(4),
.gap-2 .flex-even:first-child:nth-last-child(4) ~ .flex-even {
  min-width: calc((100% - calc(0.5rem * calc(4 - 1))) / 4);
}
.gap-2 .flex-even:first-child:nth-last-child(5),
.gap-2 .flex-even:first-child:nth-last-child(5) ~ .flex-even {
  min-width: calc((100% - calc(0.5rem * calc(5 - 1))) / 5);
}
.gap-2 .flex-even:first-child:nth-last-child(6),
.gap-2 .flex-even:first-child:nth-last-child(6) ~ .flex-even {
  min-width: calc((100% - calc(0.5rem * calc(6 - 1))) / 6);
}
.gap-2 .flex-even:first-child:nth-last-child(7),
.gap-2 .flex-even:first-child:nth-last-child(7) ~ .flex-even {
  min-width: calc((100% - calc(0.5rem * calc(7 - 1))) / 7);
}
.gap-2 .flex-even:first-child:nth-last-child(8),
.gap-2 .flex-even:first-child:nth-last-child(8) ~ .flex-even {
  min-width: calc((100% - calc(0.5rem * calc(8 - 1))) / 8);
}

@media (min-width: 576px) {
  .gap-sm-2 {
    gap: 0.5rem;
  }
}
@media (min-width: 768px) {
  .gap-md-2 {
    gap: 0.5rem;
  }
}
@media (min-width: 992px) {
  .gap-lg-2 {
    gap: 0.5rem;
  }
}
@media (min-width: 1200px) {
  .gap-xl-2 {
    gap: 0.5rem;
  }
}
.gap-3 {
  gap: 1rem;
}
.gap-3 .flex-even {
  flex: 1;
}
.gap-3 .flex-even:first-child:nth-last-child(1),
.gap-3 .flex-even:first-child:nth-last-child(1) ~ .flex-even {
  min-width: calc((100% - calc(1rem * calc(1 - 1))) / 1);
}
.gap-3 .flex-even:first-child:nth-last-child(2),
.gap-3 .flex-even:first-child:nth-last-child(2) ~ .flex-even {
  min-width: calc((100% - calc(1rem * calc(2 - 1))) / 2);
}
.gap-3 .flex-even:first-child:nth-last-child(3),
.gap-3 .flex-even:first-child:nth-last-child(3) ~ .flex-even {
  min-width: calc((100% - calc(1rem * calc(3 - 1))) / 3);
}
.gap-3 .flex-even:first-child:nth-last-child(4),
.gap-3 .flex-even:first-child:nth-last-child(4) ~ .flex-even {
  min-width: calc((100% - calc(1rem * calc(4 - 1))) / 4);
}
.gap-3 .flex-even:first-child:nth-last-child(5),
.gap-3 .flex-even:first-child:nth-last-child(5) ~ .flex-even {
  min-width: calc((100% - calc(1rem * calc(5 - 1))) / 5);
}
.gap-3 .flex-even:first-child:nth-last-child(6),
.gap-3 .flex-even:first-child:nth-last-child(6) ~ .flex-even {
  min-width: calc((100% - calc(1rem * calc(6 - 1))) / 6);
}
.gap-3 .flex-even:first-child:nth-last-child(7),
.gap-3 .flex-even:first-child:nth-last-child(7) ~ .flex-even {
  min-width: calc((100% - calc(1rem * calc(7 - 1))) / 7);
}
.gap-3 .flex-even:first-child:nth-last-child(8),
.gap-3 .flex-even:first-child:nth-last-child(8) ~ .flex-even {
  min-width: calc((100% - calc(1rem * calc(8 - 1))) / 8);
}

@media (min-width: 576px) {
  .gap-sm-3 {
    gap: 1rem;
  }
}
@media (min-width: 768px) {
  .gap-md-3 {
    gap: 1rem;
  }
}
@media (min-width: 992px) {
  .gap-lg-3 {
    gap: 1rem;
  }
}
@media (min-width: 1200px) {
  .gap-xl-3 {
    gap: 1rem;
  }
}
.gap-4 {
  gap: 1.5rem;
}
.gap-4 .flex-even {
  flex: 1;
}
.gap-4 .flex-even:first-child:nth-last-child(1),
.gap-4 .flex-even:first-child:nth-last-child(1) ~ .flex-even {
  min-width: calc((100% - calc(1.5rem * calc(1 - 1))) / 1);
}
.gap-4 .flex-even:first-child:nth-last-child(2),
.gap-4 .flex-even:first-child:nth-last-child(2) ~ .flex-even {
  min-width: calc((100% - calc(1.5rem * calc(2 - 1))) / 2);
}
.gap-4 .flex-even:first-child:nth-last-child(3),
.gap-4 .flex-even:first-child:nth-last-child(3) ~ .flex-even {
  min-width: calc((100% - calc(1.5rem * calc(3 - 1))) / 3);
}
.gap-4 .flex-even:first-child:nth-last-child(4),
.gap-4 .flex-even:first-child:nth-last-child(4) ~ .flex-even {
  min-width: calc((100% - calc(1.5rem * calc(4 - 1))) / 4);
}
.gap-4 .flex-even:first-child:nth-last-child(5),
.gap-4 .flex-even:first-child:nth-last-child(5) ~ .flex-even {
  min-width: calc((100% - calc(1.5rem * calc(5 - 1))) / 5);
}
.gap-4 .flex-even:first-child:nth-last-child(6),
.gap-4 .flex-even:first-child:nth-last-child(6) ~ .flex-even {
  min-width: calc((100% - calc(1.5rem * calc(6 - 1))) / 6);
}
.gap-4 .flex-even:first-child:nth-last-child(7),
.gap-4 .flex-even:first-child:nth-last-child(7) ~ .flex-even {
  min-width: calc((100% - calc(1.5rem * calc(7 - 1))) / 7);
}
.gap-4 .flex-even:first-child:nth-last-child(8),
.gap-4 .flex-even:first-child:nth-last-child(8) ~ .flex-even {
  min-width: calc((100% - calc(1.5rem * calc(8 - 1))) / 8);
}

@media (min-width: 576px) {
  .gap-sm-4 {
    gap: 1.5rem;
  }
}
@media (min-width: 768px) {
  .gap-md-4 {
    gap: 1.5rem;
  }
}
@media (min-width: 992px) {
  .gap-lg-4 {
    gap: 1.5rem;
  }
}
@media (min-width: 1200px) {
  .gap-xl-4 {
    gap: 1.5rem;
  }
}
.gap-5 {
  gap: 3rem;
}
.gap-5 .flex-even {
  flex: 1;
}
.gap-5 .flex-even:first-child:nth-last-child(1),
.gap-5 .flex-even:first-child:nth-last-child(1) ~ .flex-even {
  min-width: calc((100% - calc(3rem * calc(1 - 1))) / 1);
}
.gap-5 .flex-even:first-child:nth-last-child(2),
.gap-5 .flex-even:first-child:nth-last-child(2) ~ .flex-even {
  min-width: calc((100% - calc(3rem * calc(2 - 1))) / 2);
}
.gap-5 .flex-even:first-child:nth-last-child(3),
.gap-5 .flex-even:first-child:nth-last-child(3) ~ .flex-even {
  min-width: calc((100% - calc(3rem * calc(3 - 1))) / 3);
}
.gap-5 .flex-even:first-child:nth-last-child(4),
.gap-5 .flex-even:first-child:nth-last-child(4) ~ .flex-even {
  min-width: calc((100% - calc(3rem * calc(4 - 1))) / 4);
}
.gap-5 .flex-even:first-child:nth-last-child(5),
.gap-5 .flex-even:first-child:nth-last-child(5) ~ .flex-even {
  min-width: calc((100% - calc(3rem * calc(5 - 1))) / 5);
}
.gap-5 .flex-even:first-child:nth-last-child(6),
.gap-5 .flex-even:first-child:nth-last-child(6) ~ .flex-even {
  min-width: calc((100% - calc(3rem * calc(6 - 1))) / 6);
}
.gap-5 .flex-even:first-child:nth-last-child(7),
.gap-5 .flex-even:first-child:nth-last-child(7) ~ .flex-even {
  min-width: calc((100% - calc(3rem * calc(7 - 1))) / 7);
}
.gap-5 .flex-even:first-child:nth-last-child(8),
.gap-5 .flex-even:first-child:nth-last-child(8) ~ .flex-even {
  min-width: calc((100% - calc(3rem * calc(8 - 1))) / 8);
}

@media (min-width: 576px) {
  .gap-sm-5 {
    gap: 3rem;
  }
}
@media (min-width: 768px) {
  .gap-md-5 {
    gap: 3rem;
  }
}
@media (min-width: 992px) {
  .gap-lg-5 {
    gap: 3rem;
  }
}
@media (min-width: 1200px) {
  .gap-xl-5 {
    gap: 3rem;
  }
}
.text-decoration-none {
  text-decoration: none !important;
}

.bg-color-dark .bg-color-light a {
  color: rgba(0, 0, 0, 0.87);
}

.bg-color-body {
  background-color: #e5e5e5 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.bg-color-body:hover {
  background-color: #e5e5e5;
  color: rgba(0, 0, 0, 0.87);
}
.bg-color-body a {
  color: rgba(0, 0, 0, 0.87);
}
.bg-color-body a:hover {
  color: rgba(0, 0, 0, 0.87);
}

.bg-color-page {
  color: #f5f5f5 !important;
}

.bg-color-light {
  background-color: var(--text-selected) !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.bg-color-light:hover {
  background-color: var(--text-selected);
  color: rgba(0, 0, 0, 0.87);
}
.bg-color-light a {
  color: rgba(0, 0, 0, 0.87);
}
.bg-color-light a:hover {
  color: rgba(0, 0, 0, 0.87);
}

.bg-color-dark {
  background-color: var(--secondary-color) !important;
  color: var(--text-selected) !important;
}
.bg-color-dark:hover {
  background-color: var(--secondary-color);
  color: var(--text-selected);
}
.bg-color-dark a {
  color: var(--text-selected);
}
.bg-color-dark a:hover {
  color: var(--text-selected);
}

.bg-color-primary {
  background-color: var(--primary-color) !important;
  color: var(--text-selected) !important;
}
.bg-color-primary:hover {
  background-color: var(--primary-color);
  color: var(--text-selected);
}
.bg-color-primary a {
  color: var(--text-selected);
}
.bg-color-primary a:hover {
  color: var(--text-selected);
}

.bg-color-success {
  background-color: #66bb6a !important;
  color: var(--text-selected) !important;
}
.bg-color-success:hover {
  background-color: #66bb6a;
  color: var(--text-selected);
}
.bg-color-success a {
  color: var(--text-selected);
}
.bg-color-success a:hover {
  color: var(--text-selected);
}

.bg-color-info {
  background-color: #00bcd4 !important;
  color: var(--text-selected) !important;
}
.bg-color-info:hover {
  background-color: #00bcd4;
  color: var(--text-selected);
}
.bg-color-info a {
  color: var(--text-selected);
}
.bg-color-info a:hover {
  color: var(--text-selected);
}

.bg-color-warning {
  background-color: #ffca28 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.bg-color-warning:hover {
  background-color: #ffca28;
  color: rgba(0, 0, 0, 0.87);
}
.bg-color-warning a {
  color: rgba(0, 0, 0, 0.87);
}
.bg-color-warning a:hover {
  color: rgba(0, 0, 0, 0.87);
}

.bg-color-danger {
  background-color: #ef5350 !important;
  color: var(--text-selected) !important;
}
.bg-color-danger:hover {
  background-color: #ef5350;
  color: var(--text-selected);
}
.bg-color-danger a {
  color: var(--text-selected);
}
.bg-color-danger a:hover {
  color: var(--text-selected);
}

.theme-color-light .bg-color-light {
  background-color: var(--text-selected) !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.theme-color-light .bg-color-light:hover {
  background-color: var(--text-selected);
  color: rgba(0, 0, 0, 0.87);
}
.theme-color-light .bg-color-light a {
  color: rgba(0, 0, 0, 0.87);
}
.theme-color-light .bg-color-light a:hover {
  color: rgba(0, 0, 0, 0.87);
}
.theme-color-light .mat-ink-bar {
  background-color: rgba(0, 0, 0, 0.87) !important;
}
.theme-color-light .primary-color-full {
  background-color: var(--text-selected) !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.theme-color-light .primary-color-full:hover {
  background-color: var(--text-selected);
  color: rgba(0, 0, 0, 0.87);
}
.theme-color-light .primary-color-full a {
  color: rgba(0, 0, 0, 0.87);
}
.theme-color-light .primary-color-full a:hover {
  color: rgba(0, 0, 0, 0.87);
}
.theme-color-light .primary-color-text {
  color: var(--text-selected) !important;
}
.theme-color-light .primary-color-border {
  color: var(--text-selected) !important;
  border: var(--text-selected) solid 2px;
}

.bg-color-white {
  background-color: var(--text-selected) !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.bg-color-white:hover {
  background-color: var(--text-selected);
  color: rgba(0, 0, 0, 0.87);
}
.bg-color-white a {
  color: rgba(0, 0, 0, 0.87);
}
.bg-color-white a:hover {
  color: rgba(0, 0, 0, 0.87);
}

.bg-color-light-gray {
  background-color: rgba(185, 179, 179, 0.3803921569);
}

.bg-color-gray {
  background-color: #636c72 !important;
  color: var(--text-selected) !important;
}
.bg-color-gray:hover {
  background-color: #636c72;
  color: var(--text-selected);
}
.bg-color-gray a {
  color: var(--text-selected);
}
.bg-color-gray a:hover {
  color: var(--text-selected);
}

.theme-color-dark .bg-color-dark {
  background-color: var(--secondary-color) !important;
  color: var(--text-selected) !important;
}
.theme-color-dark .bg-color-dark:hover {
  background-color: var(--secondary-color);
  color: var(--text-selected);
}
.theme-color-dark .bg-color-dark a {
  color: var(--text-selected);
}
.theme-color-dark .bg-color-dark a:hover {
  color: var(--text-selected);
}
.theme-color-dark .mat-ink-bar {
  background-color: var(--secondary-color) !important;
}
.theme-color-dark .primary-color-full {
  background-color: var(--secondary-color) !important;
  color: var(--text-selected) !important;
}
.theme-color-dark .primary-color-full:hover {
  background-color: var(--secondary-color);
  color: var(--text-selected);
}
.theme-color-dark .primary-color-full a {
  color: var(--text-selected);
}
.theme-color-dark .primary-color-full a:hover {
  color: var(--text-selected);
}
.theme-color-dark .primary-color-text {
  color: var(--secondary-color) !important;
}
.theme-color-dark .primary-color-border {
  color: var(--secondary-color) !important;
  border: var(--secondary-color) solid 2px;
}

.theme-color-primary .bg-color-primary {
  background-color: var(--primary-color) !important;
  color: var(--text-selected) !important;
}
.theme-color-primary .bg-color-primary:hover {
  background-color: var(--primary-color);
  color: var(--text-selected);
}
.theme-color-primary .bg-color-primary a {
  color: var(--text-selected);
}
.theme-color-primary .bg-color-primary a:hover {
  color: var(--text-selected);
}
.theme-color-primary .mat-ink-bar {
  background-color: var(--primary-color) !important;
}
.theme-color-primary .primary-color-full {
  background-color: var(--primary-color) !important;
  color: var(--text-selected) !important;
}
.theme-color-primary .primary-color-full:hover {
  background-color: var(--primary-color);
  color: var(--text-selected);
}
.theme-color-primary .primary-color-full a {
  color: var(--text-selected);
}
.theme-color-primary .primary-color-full a:hover {
  color: var(--text-selected);
}
.theme-color-primary .primary-color-text {
  color: var(--primary-color) !important;
}
.theme-color-primary .primary-color-border {
  color: var(--primary-color) !important;
  border: var(--primary-color) solid 2px;
}

.theme-success .bg-color-success {
  background-color: #66bb6a !important;
  color: var(--text-selected) !important;
}
.theme-success .bg-color-success:hover {
  background-color: #66bb6a;
  color: var(--text-selected);
}
.theme-success .bg-color-success a {
  color: var(--text-selected);
}
.theme-success .bg-color-success a:hover {
  color: var(--text-selected);
}
.theme-success .mat-ink-bar {
  background-color: #66bb6a !important;
}
.theme-success .primary-color-full {
  background-color: #66bb6a !important;
  color: var(--text-selected) !important;
}
.theme-success .primary-color-full:hover {
  background-color: #66bb6a;
  color: var(--text-selected);
}
.theme-success .primary-color-full a {
  color: var(--text-selected);
}
.theme-success .primary-color-full a:hover {
  color: var(--text-selected);
}
.theme-success .primary-color-text {
  color: #66bb6a !important;
}
.theme-success .primary-color-border {
  color: #66bb6a !important;
  border: #66bb6a solid 2px;
}

.theme-color-info .bg-color-info {
  background-color: #00bcd4 !important;
  color: var(--text-selected) !important;
}
.theme-color-info .bg-color-info:hover {
  background-color: #00bcd4;
  color: var(--text-selected);
}
.theme-color-info .bg-color-info a {
  color: var(--text-selected);
}
.theme-color-info .bg-color-info a:hover {
  color: var(--text-selected);
}
.theme-color-info .mat-ink-bar {
  background-color: #00bcd4 !important;
}
.theme-color-info .primary-color-full {
  background-color: #00bcd4 !important;
  color: var(--text-selected) !important;
}
.theme-color-info .primary-color-full:hover {
  background-color: #00bcd4;
  color: var(--text-selected);
}
.theme-color-info .primary-color-full a {
  color: var(--text-selected);
}
.theme-color-info .primary-color-full a:hover {
  color: var(--text-selected);
}
.theme-color-info .primary-color-text {
  color: #00bcd4 !important;
}
.theme-color-info .primary-color-border {
  color: #00bcd4 !important;
  border: #00bcd4 solid 2px;
}

.bg-color-info-alt {
  background-color: #7e57c2 !important;
  color: var(--text-selected) !important;
}
.bg-color-info-alt:hover {
  background-color: #7e57c2;
  color: var(--text-selected);
}
.bg-color-info-alt a {
  color: var(--text-selected);
}
.bg-color-info-alt a:hover {
  color: var(--text-selected);
}

.theme-color-warning .bg-color-warning {
  background-color: #ffca28 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.theme-color-warning .bg-color-warning:hover {
  background-color: #ffca28;
  color: rgba(0, 0, 0, 0.87);
}
.theme-color-warning .bg-color-warning a {
  color: rgba(0, 0, 0, 0.87);
}
.theme-color-warning .bg-color-warning a:hover {
  color: rgba(0, 0, 0, 0.87);
}
.theme-color-warning .mat-ink-bar {
  background-color: #ffca28 !important;
}
.theme-color-warning .primary-color-full {
  background-color: #ffca28 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.theme-color-warning .primary-color-full:hover {
  background-color: #ffca28;
  color: rgba(0, 0, 0, 0.87);
}
.theme-color-warning .primary-color-full a {
  color: rgba(0, 0, 0, 0.87);
}
.theme-color-warning .primary-color-full a:hover {
  color: rgba(0, 0, 0, 0.87);
}
.theme-color-warning .primary-color-text {
  color: #ffca28 !important;
}
.theme-color-warning .primary-color-text {
  color: #ffca28 !important;
  border: #ffca28 solid 2px;
}

.theme-color-danger .bg-color-danger {
  background-color: #ef5350 !important;
  color: var(--text-selected) !important;
}
.theme-color-danger .bg-color-danger:hover {
  background-color: #ef5350;
  color: var(--text-selected);
}
.theme-color-danger .bg-color-danger a {
  color: var(--text-selected);
}
.theme-color-danger .bg-color-danger a:hover {
  color: var(--text-selected);
}
.theme-color-danger .mat-ink-bar {
  background-color: #ef5350 !important;
}
.theme-color-danger .primary-color-full {
  background-color: #ef5350 !important;
  color: var(--text-selected) !important;
}
.theme-color-danger .primary-color-full:hover {
  background-color: #ef5350;
  color: var(--text-selected);
}
.theme-color-danger .primary-color-full a {
  color: var(--text-selected);
}
.theme-color-danger .primary-color-full a:hover {
  color: var(--text-selected);
}
.theme-color-danger .primary-color-text {
  color: #ef5350 !important;
}
.theme-color-danger .primary-color-border {
  color: #ef5350 !important;
  border: #ef5350 solid 2px;
}

.color-option-check {
  position: relative;
  display: block;
}
.color-option-check input[type=radio] {
  display: none;
}
.color-option-check input[type=radio] + span:hover {
  cursor: pointer;
}
.color-option-check input[type=radio] + span {
  position: relative;
}
.color-option-check input[type=radio] + span > .overlay {
  display: none;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  text-align: center;
  line-height: 30px;
  color: #fff;
}
.color-option-check input[type=radio]:checked + span > .overlay {
  display: block;
}
.color-option-check .color-option-item {
  overflow: hidden;
  display: block;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.1);
  margin-bottom: 15px;
}
.color-option-check .color-option-item > span {
  display: block;
  float: left;
  width: 50%;
  height: 20px;
}
.color-option-check .color-option-item .item-header {
  height: 10px;
}

.color-option-check .bg-color-page {
  background-color: #f1f1f1;
}

.theme-options > div {
  padding: 0;
}

.theme-option-check {
  position: relative;
  display: block;
  margin: 0;
  font-weight: normal;
}
.theme-option-check input[type=radio] {
  display: none;
}
.theme-option-check input[type=radio] + span:hover {
  cursor: pointer;
}
.theme-option-check input[type=radio] + span {
  position: relative;
}
.theme-option-check input[type=radio] + span > .overlay {
  display: none;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  line-height: 60px;
  color: #fff;
}
.theme-option-check input[type=radio] + span > .overlay .material-icons {
  vertical-align: bottom;
  color: #66bb6a;
}
.theme-option-check input[type=radio]:checked + span > .overlay {
  display: block;
}
.theme-option-check .theme-option-item {
  overflow: hidden;
  display: block;
}
.theme-option-check .theme-option-item > span {
  display: block;
  text-align: center;
  height: 60px;
  line-height: 60px;
  text-transform: uppercase;
}

.app-sidebar.bg-color-light .nav {
  color: rgba(0, 0, 0, 0.87);
}
.app-sidebar.bg-color-light .nav a {
  color: rgba(0, 0, 0, 0.87);
}
.app-sidebar.bg-color-light .nav .nav-header {
  color: #636c72;
}
.app-sidebar.bg-color-light .nav li > a:hover, .app-sidebar.bg-color-light .nav li > a:focus {
  background-color: transparent;
  color: var(--primary-color);
}
.app-sidebar.bg-color-light .nav li.active > a,
.app-sidebar.bg-color-light .nav li.active > a:hover,
.app-sidebar.bg-color-light .nav li.active > a:focus {
  background-color: transparent;
  color: var(--primary-color);
}
.app-sidebar.bg-color-light .nav li.open > a,
.app-sidebar.bg-color-light .nav li.open > a:hover,
.app-sidebar.bg-color-light .nav li.open > a:focus {
  background-color: rgba(0, 0, 0, 0.05);
  color: var(--primary-color);
}
.app-sidebar.bg-color-light .nav li.open > .icon-has-ul {
  color: var(--primary-color);
}
.app-sidebar.bg-color-light .nav li > a:focus {
  background-color: transparent;
}
.app-sidebar.bg-color-light .nav ul {
  background-color: rgba(0, 0, 0, 0.05);
}
.app-sidebar.bg-color-light .nav ul li.active > a,
.app-sidebar.bg-color-light .nav ul li.active > a:hover,
.app-sidebar.bg-color-light .nav ul li.active > a:focus, .app-sidebar.bg-color-light .nav ul li.open > a,
.app-sidebar.bg-color-light .nav ul li.open > a:hover,
.app-sidebar.bg-color-light .nav ul li.open > a:focus {
  background-color: rgba(0, 0, 0, 0.05);
  color: var(--primary-color);
}
.app-sidebar.bg-color-light .nav ul ul {
  background-color: rgba(0, 0, 0, 0.05);
}
.app-sidebar.bg-color-light .nav ul ul > li.active > a,
.app-sidebar.bg-color-light .nav ul ul > li.active > a:hover,
.app-sidebar.bg-color-light .nav ul ul > li.active > a:focus, .app-sidebar.bg-color-light .nav ul ul > li.open > a,
.app-sidebar.bg-color-light .nav ul ul > li.open > a:hover,
.app-sidebar.bg-color-light .nav ul ul > li.open > a:focus {
  background-color: rgba(0, 0, 0, 0.05);
  color: var(--primary-color);
}
.app-sidebar.bg-color-light .nav ul ul ul {
  background-color: rgba(0, 0, 0, 0.05);
}
.app-sidebar.bg-color-light .sidebar-footer {
  background-color: #fafafa;
}

.app-sidebar .sidebar-header.bg-color-light a.collapsednav-toggler, .app-sidebar .sidebar-header.bg-color-warning a.collapsednav-toggler {
  color: rgba(0, 0, 0, 0.5);
}

html,
body,
.app-header {
  background-color: #e5e5e5;
}

.app-page-container .app-content-wrapper {
  background-color: #f5f5f5;
}

@media only screen and (max-width: 991px) {
  .app-page-container {
    background-color: #f5f5f5;
  }
}
.theme-gray,
.theme-dark {
  color: rgba(255, 255, 255, 0.7);
}
.theme-gray a:hover, .theme-gray a:focus,
.theme-dark a:hover,
.theme-dark a:focus {
  color: var(--primary-color);
}
.theme-gray .app-sidebar .sidebar-header,
.theme-dark .app-sidebar .sidebar-header {
  color: #fff;
}
.theme-gray .app-sidebar .sidebar-header a,
.theme-dark .app-sidebar .sidebar-header a {
  color: #fff;
}
.theme-gray .app-sidebar .sidebar-header a.collapsednav-toggler,
.theme-dark .app-sidebar .sidebar-header a.collapsednav-toggler {
  color: #fff;
  opacity: 0.5;
}
.theme-gray .app-overlay,
.theme-dark .app-overlay {
  background: rgba(0, 0, 0, 0.7);
  color: rgba(255, 255, 255, 0.7);
}
.theme-gray .app-overlay input,
.theme-dark .app-overlay input {
  color: rgba(255, 255, 255, 0.7);
}
.theme-gray .app-overlay a.overlay-close,
.theme-dark .app-overlay a.overlay-close {
  color: #636c72;
}
.theme-gray .app-sidebar .sidebar-header,
.theme-dark .app-sidebar .sidebar-header {
  color: var(--text-color);
}
.theme-gray .app-sidebar .sidebar-header a,
.theme-dark .app-sidebar .sidebar-header a {
  color: var(--text-color) !important;
}
.theme-gray .app-footer .brand,
.theme-dark .app-footer .brand {
  color: rgba(255, 255, 255, 0.7);
}

.theme-gray .app-sidebar {
  background-color: hsl(var(var(var(--secondary-color))-h), calc(var(var(var(--secondary-color))-s) * 0), var(var(var(--secondary-color))-l));
}
.theme-gray .app-sidebar .sidebar-header {
  background-color: hsl(var(var(var(--secondary-color))-h), calc(var(var(var(--secondary-color))-s) * 0), var(var(var(--secondary-color))-l));
}
.theme-gray .app-sidebar .sidebar-footer {
  background-color: hsl(var(var(var(--secondary-color))-h), calc(var(var(var(--secondary-color))-s) * 0), var(var(var(--secondary-color))-l));
}
.theme-gray .app-sidebar .nav {
  color: var(--text-color);
}
.theme-gray .app-sidebar .nav a {
  color: var(--text-color);
}
.theme-gray .app-page-container .app-content-wrapper {
  background-color: hsl(var(var(linear-gradient(to top, rgba(255, 255, 255, 0.025), rgba(255, 255, 255, 0.025), var(--secondary-color)))-h), calc(var(var(linear-gradient(to top, rgba(255, 255, 255, 0.025), rgba(255, 255, 255, 0.025), var(--secondary-color)))-s) * 0), var(var(linear-gradient(to top, rgba(255, 255, 255, 0.025), rgba(255, 255, 255, 0.025), var(--secondary-color)))-l));
}
@media only screen and (max-width: 991px) {
  .theme-gray .app-page-container {
    background-color: hsl(var(var(linear-gradient(to top, rgba(255, 255, 255, 0.025), rgba(255, 255, 255, 0.025), var(--secondary-color)))-h), calc(var(var(linear-gradient(to top, rgba(255, 255, 255, 0.025), rgba(255, 255, 255, 0.025), var(--secondary-color)))-s) * 0), var(var(linear-gradient(to top, rgba(255, 255, 255, 0.025), rgba(255, 255, 255, 0.025), var(--secondary-color)))-l));
  }
}
.theme-gray .app-header-inner.bg-color-light, .theme-gray .app-header-inner.bg-color-dark, .theme-gray .app-header-inner.bg-color-primary, .theme-gray .app-header-inner.bg-color-success, .theme-gray .app-header-inner.bg-color-info, .theme-gray .app-header-inner.bg-color-warning, .theme-gray .app-header-inner.bg-color-danger {
  background-color: hsl(var(var(linear-gradient(to top, rgba(255, 255, 255, 0.06), rgba(255, 255, 255, 0.06), var(--secondary-color)))-h), calc(var(var(linear-gradient(to top, rgba(255, 255, 255, 0.06), rgba(255, 255, 255, 0.06), var(--secondary-color)))-s) * 0), var(var(linear-gradient(to top, rgba(255, 255, 255, 0.06), rgba(255, 255, 255, 0.06), var(--secondary-color)))-l));
  color: rgba(255, 255, 255, 0.7);
}
.theme-gray .app-header-inner.bg-color-light a, .theme-gray .app-header-inner.bg-color-dark a, .theme-gray .app-header-inner.bg-color-primary a, .theme-gray .app-header-inner.bg-color-success a, .theme-gray .app-header-inner.bg-color-info a, .theme-gray .app-header-inner.bg-color-warning a, .theme-gray .app-header-inner.bg-color-danger a {
  color: rgba(255, 255, 255, 0.7);
}

.theme-dark .app-sidebar {
  background-color: var(--secondary-color);
}
.theme-dark .app-sidebar .sidebar-header {
  background-color: var(--secondary-color);
}
.theme-dark .app-sidebar .sidebar-footer {
  background-color: var(--secondary-color);
}
.theme-dark .app-sidebar .nav {
  color: var(--text-color);
}
.theme-dark .app-sidebar .nav a {
  color: var(--text-color);
}
.theme-dark .app-page-container .app-content-wrapper {
  background-color: linear-gradient(to top, rgba(255, 255, 255, 0.025), rgba(255, 255, 255, 0.025), var(--secondary-color));
}
@media only screen and (max-width: 991px) {
  .theme-dark .app-page-container {
    background-color: linear-gradient(to top, rgba(255, 255, 255, 0.025), rgba(255, 255, 255, 0.025), var(--secondary-color));
  }
}
.theme-dark .app-header-inner.bg-color-light, .theme-dark .app-header-inner.bg-color-dark, .theme-dark .app-header-inner.bg-color-primary, .theme-dark .app-header-inner.bg-color-success, .theme-dark .app-header-inner.bg-color-info, .theme-dark .app-header-inner.bg-color-warning, .theme-dark .app-header-inner.bg-color-danger {
  background-color: linear-gradient(to top, rgba(255, 255, 255, 0.06), rgba(255, 255, 255, 0.06), var(--secondary-color));
  color: rgba(255, 255, 255, 0.7);
}
.theme-dark .app-header-inner.bg-color-light a, .theme-dark .app-header-inner.bg-color-dark a, .theme-dark .app-header-inner.bg-color-primary a, .theme-dark .app-header-inner.bg-color-success a, .theme-dark .app-header-inner.bg-color-info a, .theme-dark .app-header-inner.bg-color-warning a, .theme-dark .app-header-inner.bg-color-danger a {
  color: rgba(255, 255, 255, 0.7);
}

.theme-dark {
  background-color: linear-gradient(to top, rgba(255, 255, 255, 0.025), rgba(255, 255, 255, 0.025), var(--secondary-color));
}

.theme-gray {
  background-color: hsl(var(var(linear-gradient(to top, rgba(255, 255, 255, 0.025), rgba(255, 255, 255, 0.025), var(--secondary-color)))-h), calc(var(var(linear-gradient(to top, rgba(255, 255, 255, 0.025), rgba(255, 255, 255, 0.025), var(--secondary-color)))-s) * 0), var(var(linear-gradient(to top, rgba(255, 255, 255, 0.025), rgba(255, 255, 255, 0.025), var(--secondary-color)))-l));
}