@import 'variables';
@import 'vendors/material2/material2';
@import 'pages/demo';
@import 'pages/auth';
@import 'pages/error';
@import 'pages/profile';
@import 'pages/invoice';
@import 'pages/dashboard';
@import 'pages/maintenance';
@import 'pages/about';
@import 'pages/terms';
@import 'pages/blog';
@import 'pages/support';
@import 'pages/scheduler';
@import 'pages/custom-calendar';
@import 'pages/availability-calendar';
@import 'virtual-care/booking';
@import 'global/secondary-nav/main';
@import 'global/ng2-file-input';
@import 'global/ckeditor';

:root {
  --primary-color: #009fdf; // insig blue
  --secondary-color: #ffffff; // light grey
  --text-color: #000105; // dark grey
  --text-secondary: #a1a1a1;
  --text-selected: #ffffff;
}

.goog-te-banner-frame.skiptranslate {
  display: none !important;
}

#google_translate_element {
  display: none;
}

#google_translate_element a {
  display: none;
}

.goog-tooltip {
  display: none !important;
}

.goog-tooltip:hover {
  display: none !important;
}

.goog-text-highlight {
  background-color: transparent !important;
  border: none !important;
  box-shadow: none !important;
}

.skiptranslate {
  display: none !important;
}

.app-padding-bottom {
  padding-bottom: 50px;
}

.second-navbar-padding {
  padding-top: 64px;
}

.vertical-divider {
  border-top-width: 0;
  border-right-width: 1px;
  border-right-style: solid;
  height: 100%;
}

.fill-available {
  min-height: 100%;
}

body {
  top: 0 !important;
}

.mat-form-field.mat-focused .mat-form-field-label {
  color: var(--primary-color) !important;
}

.mat-form-field.mat-focused .mat-form-field-ripple {
  background-color: var(--primary-color) !important;
}

.mat-input-element {
  caret-color: var(--primary-color) !important;
}

.mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
  color: var(--primary-color) !important;
}

.mdc-menu-surface--open {
  opacity: 1 !important;
  transform: scale(1) !important;
}

.mat-mdc-dialog-container .mdc-dialog__content {
  color: inherit !important;
}

.mat-mdc-dialog-container .mdc-dialog__content {
  line-height: inherit !important;
  font-size: inherit !important;
  font-weight: inherit !important;
  letter-spacing: inherit !important;
}

.mat-mdc-dialog-container .mdc-dialog__title {
  font-size: calc(22px + 0.25vw) !important;
  font-weight: 600 !important;
  font-family: inherit !important;
  line-height: inherit !important;
  letter-spacing: inherit !important;
  padding-top: 20px !important;
}

.mdc-dialog__title::before {
  display: none !important;
}

.mdc-dialog__actions {
  padding: 0px 24px 20px 24px !important;
}

.mat-mdc-button {
  height: unset !important;
  min-height: 36px;
}

.mat-mdc-button-base {
  flex-shrink: 0 !important;
}

.mat-mdc-form-field-subscript-wrapper {
  background-color: transparent !important;
}

button.mdc-fab[color="none"] {
  background-color: white;
}

input.mat-mdc-input-element::-webkit-calendar-picker-indicator {
  display: block;
}

.mdc-dialog .mdc-dialog__content {
  padding: 0 !important;
  margin: 20px 24px !important;
}

mat-dialog-container {
  padding: 0 !important;
}

.mdc-switch__icons {
  display: none !important;
}

.mat-mdc-form-field-error-wrapper {
  position: relative !important;
  top: -16px !important;
}

.mdc-list-item__primary-text {
  flex-grow: 1;
}
