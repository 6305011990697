// Light background sidebar
// --------------------------------------------------
$light_nav_text_color: $body-color;
$light_nav_link_hover_bg: transparent; //#f6f6f6;
$light_nav_link_hover_color: var(--primary-color);
$light_nav_link_active_bg: transparent;
$light_nav_link_active_color: var(--primary-color);
$light_nav_link_open_bg: rgba(0, 0, 0, 0.05); // vertical nav only
$light_nav_link_open_color: $light_nav_link_active_color; // vertical nav only

$light_nav_sub_nav_bg: $light_nav_link_open_bg;
$light_nav_sub_link_active_color: var(--primary-color);
// $light_nav_sub2_nav_bg:               #eaeaea;
$light_nav_sub2_nav_bg: rgba(0, 0, 0, 0.05);
// $light_nav_sub3_nav_bg:               #ddd;
$light_nav_sub3_nav_bg: rgba(0, 0, 0, 0.05);

// Themes
// --------------------------------------------------
@function grayscale($color, $saturationMultiplier) {
  $color-h: var(#{$color + '-h'});
  $color-s: var(#{$color + '-s'});
  $color-l: var(#{$color + '-l'});
  @return hsl($color-h, calc(#{$color-s} * #{$saturationMultiplier}), $color-l);
}

$theme_dark_text_color: rgba(255, 255, 255, 0.7);
$theme_dark_text_color_light: rgba(255, 255, 255, 0.25);

$theme_dark_sidebar_bg_color: $dark;
$theme_dark_header_bg_color: lighten($dark, 6%);
$theme_dark_page_bg: lighten($dark, 2.5%);
$theme_dark_box_bg: $theme_dark_header_bg_color;

$theme_gray_sidebar_bg_color: grayscale(
  var($theme_dark_sidebar_bg_color),
  0
); // or 3c3c3c
$theme_gray_header_bg_color: grayscale(
  var($theme_dark_header_bg_color),
  0
); // or #4c4c4c
$theme_gray_page_bg: grayscale(var($theme_dark_page_bg), 0); // #444;
$theme_gray_text_color: $theme_dark_text_color;
$theme_gray_box_bg: $theme_gray_header_bg_color;
