//
// Border
//

.border {
  border: 1px solid #dee2e6 !important;
}

.border-0        { border: 0 !important; }
.border-top-0    { border-top: 0 !important; }
.border-right-0  { border-right: 0 !important; }
.border-bottom-0 { border-bottom: 0 !important; }
.border-left-0   { border-left: 0 !important; }

//
// Border-radius
//

.rounded {
  @include border-radius($border-radius);
}
.rounded-top {
  @include border-top-radius($border-radius);
}
.rounded-right {
  @include border-right-radius($border-radius);
}
.rounded-bottom {
  @include border-bottom-radius($border-radius);
}
.rounded-left {
  @include border-left-radius($border-radius);
}

.rounded-circle {
  border-radius: 50%;
}

.rounded-0 {
  border-radius: 0;
}
