section.custom-calendar {
  .cal-month-view {
    .month-title-bar {
      // background: #365769 !important;
      color: white;
      width: 100%;
      margin: 0;
    }
    .cal-header {
      text-align: center;
      font-weight: bolder;
      // background:#264452 !important;
      color: white;
    }

    .cal-cell-row:hover {
      background-color: #fafafa;
    }

    .cal-header .cal-cell {
      padding: 5px 0;
      overflow: hidden;
      text-overflow: ellipsis;
      display: block;
      white-space: nowrap;
    }

    .cal-cell-row .cal-cell:hover,
    .cal-cell.cal-has-events.cal-open {
      background-color: #ededed;
    }

    .cal-days {
      border: 1px solid #e1e1e1;
      border-bottom: 0;
    }

    .cal-cell-top {
      margin-right: auto;
      min-height: 50px;
      flex: 1;
      text-align: center;
    }

    .cal-cell-row {
      display: flex;
    }

    .cal-cell {
      float: left;
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: stretch;
    }

    .cal-day-cell {
      min-width: 0;
      min-height: 12vh;
    }

    .cal-day-cell:not(:last-child) {
      border-right: 1px solid #e1e1e1;
    }

    .cal-days .cal-cell-row {
      border-bottom: 1px solid #e1e1e1;
    }

    .cal-day-badge {
      margin-top: 18px;
      margin-left: 10px;
      // background-color: #b94a48;
      display: inline-block;
      min-width: 10px;
      padding: 3px 7px;
      font-size: 12px;
      font-weight: 700;
      line-height: 1;
      color: grey;
      opacity: 0.5;
      // color: white;
      text-align: center;
      vertical-align: middle;
      // border-radius: 10px;
    }

    .cal-has-events:hover {
      .cal-day-number {
        color: white;
        background: #56c477;
      }
    }

    .cal-has-events {
      background: #e8fde7;
    }
    .cal-has-events .cal-day-number {
      border: none;

      // border: 2px solid #56c477;
      color: #000;
      display: inline-block;
      -moz-border-radius: 30px;
      -webkit-border-radius: 30px;
      border-radius: 30px;
      width: 50px;
      height: 50px;
      line-height: 49px;
      -webkit-transition: background 0.15s ease-out, color 0.15s ease-out;
      -moz-transition: background 0.15s ease-out, color 0.15s ease-out;
      -o-transition: background 0.15s ease-out, color 0.15s ease-out;
      transition: background 0.15s ease-out, color 0.15s ease-out;
      // margin-top:25%;
    }

    .cal-has-events .cal-day-number:hover {
      color: white;
      background: #56c477;
    }

    .cal-has-no-events {
      background: #fff9f8 !important;
    }
    .cal-has-no-events:hover {
      .cal-day-number {
        color: white;
        background: red;
      }
    }

    .cal-has-no-events .cal-day-number {
      border: none;
      // background: #fff;
      // border: 2px solid #56c477;
      color: #ffa790;
      display: inline-block;
      -moz-border-radius: 30px;
      -webkit-border-radius: 30px;
      border-radius: 30px;
      width: 50px;
      height: 50px;
      line-height: 49px;
      -webkit-transition: background 0.15s ease-out, color 0.15s ease-out;
      -moz-transition: background 0.15s ease-out, color 0.15s ease-out;
      -o-transition: background 0.15s ease-out, color 0.15s ease-out;
      transition: background 0.15s ease-out, color 0.15s ease-out;
      // margin-top:25%;
    }

    .cal-has-no-events .cal-day-number:hover {
      color: white;
      background: red;
    }

    .cal-past {
      cursor: not-allowed;
      background: #f5f5f5 !important;
      color: #ccc !important;
    }

    .cal-past:hover {
      .cal-day-number {
        color: inherit;
        background: transparent;
      }
    }

    .cal-past .cal-day-number {
      border: none;
      // background: #fff;
      // border: 2px solid #56c477;
      color: grey;
      display: inline-block;
      -moz-border-radius: 30px;
      -webkit-border-radius: 30px;
      border-radius: 30px;
      width: 50px;
      height: 50px;
      line-height: 49px;
      -webkit-transition: background 0.15s ease-out, color 0.15s ease-out;
      -moz-transition: background 0.15s ease-out, color 0.15s ease-out;
      -o-transition: background 0.15s ease-out, color 0.15s ease-out;
      transition: background 0.15s ease-out, color 0.15s ease-out;
      // margin-top:25%;
    }

    .cal-past .cal-day-number:hover {
      color: inherit;
      background: transparent;
    }

    .cal-day-number {
      // font-size: 1.2em;
      // font-weight: 400;
      // margin-top: 15px;
      // margin-right: 15px;
      // float: right;
      // margin-bottom: 10px;
      border: none;
      // background: #fff;
      // border: 2px solid #56c477;
      color: #000;
      display: inline-block;
      -moz-border-radius: 30px;
      -webkit-border-radius: 30px;
      border-radius: 30px;
      width: 50px;
      height: 50px;
      line-height: 49px;
      // margin-top:25%;
    }

    .cal-events {
      flex: 1;
      align-items: flex-end;
      margin: 3px;
      line-height: 10px;
      display: flex;
      flex-wrap: wrap;
    }

    .cal-event {
      width: 10px;
      height: 10px;
      border-radius: 50%;
      display: inline-block;
      margin: 2px;
    }

    .cal-day-cell.cal-in-month.cal-has-events {
      cursor: pointer;
    }

    .cal-day-cell.cal-out-month .cal-day-number {
      opacity: 0.3;
      cursor: default;
    }

    .cal-day-cell.cal-weekend .cal-day-number {
      color: darkred;
    }

    .cal-day-cell.cal-today {
      background-color: #e8fde7;
    }

    .cal-day-cell.cal-today .cal-day-number {
      font-size: 1.9em;
    }

    .cal-day-cell.cal-drag-over {
      background-color: darken(#ededed, 5%) !important;
    }

    .cal-open-day-events {
      padding: 15px;
      color: white;
      background-color: #365769;
      box-shadow: inset 0 0 15px 0 rgba(0, 0, 0, 0.5);
    }

    .cal-open-day-events .cal-event {
      position: relative;
      top: 2px;
    }

    .cal-event-title {
      color: white;
    }

    .cal-out-month {
      .cal-day-badge,
      .cal-event {
        opacity: 0.3;
      }
    }
  }

  .cal-week-view {
    .cal-day-headers {
      display: flex;
      margin-bottom: 3px;
      border: 1px solid #e1e1e1;
      margin-left: 2px;
      margin-right: 2px;
    }

    .cal-day-headers .cal-header {
      flex: 1;
      text-align: center;
      padding: 5px;
    }

    .cal-day-headers .cal-header:not(:last-child) {
      border-right: 1px solid #e1e1e1;
    }

    .cal-day-headers .cal-header:hover,
    .cal-day-headers .cal-drag-over {
      background-color: #ededed;
    }

    .cal-day-headers span {
      font-weight: 400;
      opacity: 0.5;
    }

    .cal-events-row {
      position: relative;
      height: 33px;
    }

    .cal-event-container {
      display: inline-block;
      position: absolute;
    }

    .cal-event {
      padding: 0 10px;
      font-size: 12px;
      margin-left: 2px;
      margin-right: 2px;
      height: 30px;
      line-height: 30px;
    }

    .cal-draggable {
      cursor: move;
    }

    .cal-event.cal-starts-within-week {
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
    }

    .cal-event.cal-ends-within-week {
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
    }

    .cal-header.cal-today {
      background-color: #e8fde7;
    }

    .cal-header.cal-weekend span {
      color: #8b0000;
    }

    .cal-event,
    .cal-header {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  .cal-day-view {
    .cal-hour-rows {
      width: 100%;
      border: solid 1px #e1e1e1;
      overflow-x: scroll;
      position: relative;
    }

    .cal-hour:nth-child(odd) {
      background-color: #fafafa;
    }

    /* stylelint-disable-next-line selector-type-no-unknown */
    mwl-calendar-day-view-hour-segment, // fix for https://github.com/mattlewis92/angular-calendar/issues/260
    .cal-hour-segment {
      display: block;
      height: 30px;
    }

    .cal-hour-segment::after {
      content: '\00a0';
    }

    .cal-hour:not(:last-child) .cal-hour-segment,
    .cal-hour:last-child :not(:last-child) .cal-hour-segment {
      border-bottom: thin dashed #e1e1e1;
    }

    .cal-time {
      font-weight: bold;
      padding-top: 5px;
      width: 70px;
      text-align: center;
    }

    .cal-hour-segment.cal-after-hour-start {
      .cal-time {
        display: none;
      }
    }

    .cal-hour-segment:hover,
    .cal-drag-over .cal-hour-segment {
      background-color: #ededed;
    }

    .cal-event-container {
      position: absolute;
    }

    .cal-event {
      border: solid 1px;
      padding: 5px;
      font-size: 12px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      height: 100%;
      box-sizing: border-box;
    }

    .cal-draggable {
      cursor: move;
    }

    .cal-event.cal-starts-within-day {
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
    }

    .cal-event.cal-ends-within-day {
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
    }

    .cal-all-day-event {
      padding: 8px;
      border: solid 1px;
    }
  }

  .cal-tooltip {
    position: absolute;
    z-index: 1070;
    display: block;
    font-style: normal;
    font-weight: normal;
    letter-spacing: normal;
    line-break: auto;
    line-height: 1.5;
    text-align: start;
    text-decoration: none;
    text-shadow: none;
    text-transform: none;
    white-space: normal;
    word-break: normal;
    word-spacing: normal;
    font-size: 14px;
    word-wrap: break-word;
    opacity: 0.9;
  }

  .cal-tooltip.cal-tooltip-top {
    padding: 5px 0;
    margin-top: -3px;
  }

  .cal-tooltip.cal-tooltip-top .cal-tooltip-arrow {
    bottom: 0;
    left: 50%;
    margin-left: -5px;
    border-width: 5px 5px 0;
    border-top-color: #000;
  }

  .cal-tooltip.cal-tooltip-right {
    padding: 0 5px;
    margin-left: 3px;
  }

  .cal-tooltip.cal-tooltip-right .cal-tooltip-arrow {
    top: 50%;
    left: 0;
    margin-top: -5px;
    border-width: 5px 5px 5px 0;
    border-right-color: #000;
  }

  .cal-tooltip.cal-tooltip-bottom {
    padding: 5px 0;
    margin-top: 3px;
  }

  .cal-tooltip.cal-tooltip-bottom .cal-tooltip-arrow {
    top: 0;
    left: 50%;
    margin-left: -5px;
    border-width: 0 5px 5px;
    border-bottom-color: #000;
  }

  .cal-tooltip.cal-tooltip-left {
    padding: 0 5px;
    margin-left: -3px;
  }

  .cal-tooltip.cal-tooltip-left .cal-tooltip-arrow {
    top: 50%;
    right: 0;
    margin-top: -5px;
    border-width: 5px 0 5px 5px;
    border-left-color: #000;
  }

  .cal-tooltip-inner {
    max-width: 200px;
    padding: 3px 8px;
    color: black;
    text-align: center;
    border: 1px solid grey;
    background-color: white;
    border-radius: 0.25rem;
  }

  .cal-tooltip-arrow {
    position: absolute;
    width: 0;
    height: 0;
    border-color: transparent;
    border-style: solid;
  }
}
