.gap-0 {
  gap: 0;
}

.gap-1 {
  gap: 0.25rem;
}

.gap-2 {
  gap: 0.5rem;
}

.gap-3 {
  gap: 1rem;
}

.gap-4 {
  gap: 1.5rem;
}

.gap-5 {
  gap: 3rem;
}

.flex-fill {
  flex: 1 1 auto;
}

.mat-dialog-actions {
  min-height: auto !important;
  padding: 0 !important;
  margin-bottom: 0 !important;
}

.mat-dialog-container {
  border-radius: 10px !important;
}

.primary-color-full {
  background-color: var(--primary-color) !important;
  color: white !important;
}

.position-absolute {
  position: absolute;
}

.pillwayDialogLogo {
  max-width: 100%;
  max-height: 80px;
}

.ngxFileDropZone {
  min-width: 200px;
  min-height: 50px;
  border: 2px dashed #333;
  color: inherit;
  padding: 7px;
}

.tiaDelivery {
  color: #009fde;
  font-weight: bold;
  font-size: 23px;
}

.tiaDelivery2 {
  color: #009fde;
  font-weight: bold;
  font-size: 18px;
}

.bg-dark {
  background: black;
}

.bg-transparent {
  background: transparent;
}

.text-light {
  color: white;
}

.text-dark {
  color: #343a40 !important;
}

.ngxFileDropContent {
  display: flex;
  align-items: center;
  justify-content: center;
}

.rounded-0-important {
  border-radius: 0 !important;
}

.App-video-container .video-container video {
  height: 100%;
  max-width: 100%;
}

.App-video-container .video-container.small video {
  height: auto;
  width: auto;
  max-height: 100%;
}

.mat-dialog-content {
  margin: 0 !important;
  padding: 0 !important;
}

.cursor-pointer {
  cursor: pointer;
}

.position-relative {
  position: relative;
}

.editor-actions {
  bottom: initial !important;
  z-index: 1000 !important;
  width: initial !important;
}

.m-0-children * {
  margin: 0 !important;
}

.p-0-children * {
  padding: 0 !important;
}

.mat-menu-panel {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  min-width: 112px;
  max-width: 280px;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  max-height: calc(100vh - 48px);
  border-radius: 2px;
  outline: 0;
}

.mat-menu-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}

.mat-menu-panel.mat-menu-after.mat-menu-below {
  transform-origin: left top;
}

.mat-menu-panel.mat-menu-after.mat-menu-above {
  transform-origin: left bottom;
}

.mat-menu-panel.mat-menu-before.mat-menu-below {
  transform-origin: right top;
}

.mat-menu-panel.mat-menu-before.mat-menu-above {
  transform-origin: right bottom;
}

[dir=rtl] .mat-menu-panel.mat-menu-after.mat-menu-below {
  transform-origin: right top;
}

[dir=rtl] .mat-menu-panel.mat-menu-after.mat-menu-above {
  transform-origin: right bottom;
}

[dir=rtl] .mat-menu-panel.mat-menu-before.mat-menu-below {
  transform-origin: left top;
}

[dir=rtl] .mat-menu-panel.mat-menu-before.mat-menu-above {
  transform-origin: left bottom;
}

.mat-menu-panel.ng-animating {
  pointer-events: none;
}

@media screen and (-ms-high-contrast: active) {
  .mat-menu-panel {
    outline: solid 1px;
  }
}
.mat-menu-content {
  padding-top: 8px;
  padding-bottom: 8px;
}

.mat-menu-item {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
  outline: 0;
  border: none;
  -webkit-tap-highlight-color: transparent;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  line-height: 48px;
  height: 48px;
  padding: 0 16px;
  text-align: left;
  text-decoration: none;
  position: relative;
}

.mat-menu-item[disabled] {
  cursor: default;
}

[dir=rtl] .mat-menu-item {
  text-align: right;
}

.mat-menu-item .mat-icon {
  margin-right: 16px;
  vertical-align: middle;
}

[dir=rtl] .mat-menu-item .mat-icon {
  margin-left: 16px;
  margin-right: 0;
}

.mat-menu-item-submenu-trigger {
  padding-right: 32px;
}

.mat-menu-item-submenu-trigger::after {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 5px 0 5px 5px;
  border-color: transparent transparent transparent currentColor;
  content: "";
  display: inline-block;
  position: absolute;
  top: 50%;
  right: 16px;
  transform: translateY(-50%);
}

[dir=rtl] .mat-menu-item-submenu-trigger {
  padding-right: 16px;
  padding-left: 32px;
}

[dir=rtl] .mat-menu-item-submenu-trigger::after {
  right: auto;
  left: 16px;
  transform: rotateY(180deg) translateY(-50%);
}

button.mat-menu-item {
  width: 100%;
}

.mat-menu-ripple {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  pointer-events: none;
}

.modal-backdrop {
  background-color: rgba(0, 0, 0, 0.5);
}

.hr-text {
  border-top: 0 !important;
  line-height: 1em;
  position: relative;
  outline: 0;
  border: 0;
  color: black;
  text-align: center;
  height: 1.5em;
  opacity: 0.5;
}
.hr-text:before {
  content: "";
  background: linear-gradient(to right, transparent, #818078, transparent);
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  height: 1px;
}
.hr-text:after {
  content: attr(data-content);
  position: relative;
  display: inline-block;
  color: black;
  padding: 0 0.5em;
  line-height: 1.5em;
  color: #818078;
  background-color: #fcfcfa;
}

.modal-body {
  max-height: calc(80vh - 200px);
  overflow-y: auto;
}

.mat-divider {
  border-top-width: 0.11em !important;
}

.fontWeight350 {
  font-weight: 350;
}

.lineHeight1o4em {
  line-height: 1.4em;
}

.bkg-white {
  background-color: #fff;
}

.m-hover-transition {
  margin-right: 1em;
  transition: margin-left 0.5s ease-in-out, margin-right 0.5s ease-in-out;
}

.m-hover-transition:hover {
  margin-left: 0.5em;
  margin-right: 0.5em;
}

.cke_screen_reader_only {
  /* ckeditor for screen readers; currently causes styling issues so we are disabling it */
  display: none;
}

.pointer-events-none {
  pointer-events: none;
}

.d-initial {
  display: initial;
}

.overflow-x-auto {
  overflow-x: auto;
}

.overflow-x-hidden {
  overflow-x: hidden;
}

.overflow-y-auto {
  overflow-y: auto;
}

.overflow-hidden {
  overflow: hidden;
}

.text-black {
  color: #000 !important;
}

.mw-100 {
  max-width: 100;
}

.w-100vw {
  width: 100vw;
}

h4 {
  line-height: 1.2;
}

.flex {
  flex: 1;
}

.flex-even {
  flex: 1 1 0;
}

.flex-static {
  flex: 0 0 auto;
}

.centeringMargin {
  margin: 0 auto;
}

.mat-form-field-underline {
  height: 0.11em !important;
}

.mat-select-underline {
  height: 0.11em !important;
}

.goog-te-banner {
  display: none !important;
}

iframe.goog-te-banner-frame {
  display: none !important;
}

.goog-te-banner-frame {
  display: none !important;
}

.goog-tooltip {
  display: none !important;
}

.goog-logo-link {
  display: none !important;
}

.goog-te-gadget {
  color: transparent !important;
  margin-top: 0.5cm;
}

.mat-input-placeholder {
  color: rgba(0, 0, 0, 0.7);
}

.goog-text-highlight {
  background-color: rgb(255, 255, 255) !important;
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important;
  box-sizing: none !important;
  -webkit-box-sizing: none !important;
  -moz-box-sizing: none !important;
}

.min-height-0 {
  min-height: 0 !important;
}

.min-width-0 {
  min-width: 0;
}

.flex-grow-1 {
  flex-grow: 1;
}

.w-fit-content {
  width: fit-content;
}

.min-vw-50 {
  min-width: 50vw;
}

.w-xs-auto {
  width: auto !important;
}

@media (min-width: 576px) {
  .w-sm-auto {
    width: auto !important;
  }
}
@media (min-width: 768px) {
  .w-md-auto {
    width: auto !important;
  }
}
@media (min-width: 992px) {
  .w-lg-auto {
    width: auto !important;
  }
}
@media (min-width: 1200px) {
  .w-xl-auto {
    width: auto !important;
  }
}
.bg-white {
  background-color: white !important;
}

@media (min-width: 768px) {
  .d-md-grid {
    display: grid !important;
  }
  .w-md-fit-content {
    width: fit-content !important;
  }
}
.grid-cols-3 {
  grid-template-columns: repeat(3, minmax(0, 1fr));
}

.h-fit-content {
  height: fit-content !important;
}

.overflow-auto {
  overflow: auto;
}

.min-dvh-35 {
  min-height: 35dvh;
}

.min-h-inherit {
  min-height: inherit;
}

.rounded-lg {
  border-radius: 0.3rem !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-2 {
  flex-shrink: 2 !important;
}

.flex-grow-2 {
  flex-grow: 2 !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.gap-0 {
  gap: 0;
}
.gap-0 .flex-even {
  flex: 1;
}
.gap-0 .flex-even:first-child:nth-last-child(1),
.gap-0 .flex-even:first-child:nth-last-child(1) ~ .flex-even {
  min-width: calc((100% - calc(0 * calc(1 - 1))) / 1);
}
.gap-0 .flex-even:first-child:nth-last-child(2),
.gap-0 .flex-even:first-child:nth-last-child(2) ~ .flex-even {
  min-width: calc((100% - calc(0 * calc(2 - 1))) / 2);
}
.gap-0 .flex-even:first-child:nth-last-child(3),
.gap-0 .flex-even:first-child:nth-last-child(3) ~ .flex-even {
  min-width: calc((100% - calc(0 * calc(3 - 1))) / 3);
}
.gap-0 .flex-even:first-child:nth-last-child(4),
.gap-0 .flex-even:first-child:nth-last-child(4) ~ .flex-even {
  min-width: calc((100% - calc(0 * calc(4 - 1))) / 4);
}
.gap-0 .flex-even:first-child:nth-last-child(5),
.gap-0 .flex-even:first-child:nth-last-child(5) ~ .flex-even {
  min-width: calc((100% - calc(0 * calc(5 - 1))) / 5);
}
.gap-0 .flex-even:first-child:nth-last-child(6),
.gap-0 .flex-even:first-child:nth-last-child(6) ~ .flex-even {
  min-width: calc((100% - calc(0 * calc(6 - 1))) / 6);
}
.gap-0 .flex-even:first-child:nth-last-child(7),
.gap-0 .flex-even:first-child:nth-last-child(7) ~ .flex-even {
  min-width: calc((100% - calc(0 * calc(7 - 1))) / 7);
}
.gap-0 .flex-even:first-child:nth-last-child(8),
.gap-0 .flex-even:first-child:nth-last-child(8) ~ .flex-even {
  min-width: calc((100% - calc(0 * calc(8 - 1))) / 8);
}

@media (min-width: 576px) {
  .gap-sm-0 {
    gap: 0;
  }
}
@media (min-width: 768px) {
  .gap-md-0 {
    gap: 0;
  }
}
@media (min-width: 992px) {
  .gap-lg-0 {
    gap: 0;
  }
}
@media (min-width: 1200px) {
  .gap-xl-0 {
    gap: 0;
  }
}
.gap-1 {
  gap: 0.25rem;
}
.gap-1 .flex-even {
  flex: 1;
}
.gap-1 .flex-even:first-child:nth-last-child(1),
.gap-1 .flex-even:first-child:nth-last-child(1) ~ .flex-even {
  min-width: calc((100% - calc(0.25rem * calc(1 - 1))) / 1);
}
.gap-1 .flex-even:first-child:nth-last-child(2),
.gap-1 .flex-even:first-child:nth-last-child(2) ~ .flex-even {
  min-width: calc((100% - calc(0.25rem * calc(2 - 1))) / 2);
}
.gap-1 .flex-even:first-child:nth-last-child(3),
.gap-1 .flex-even:first-child:nth-last-child(3) ~ .flex-even {
  min-width: calc((100% - calc(0.25rem * calc(3 - 1))) / 3);
}
.gap-1 .flex-even:first-child:nth-last-child(4),
.gap-1 .flex-even:first-child:nth-last-child(4) ~ .flex-even {
  min-width: calc((100% - calc(0.25rem * calc(4 - 1))) / 4);
}
.gap-1 .flex-even:first-child:nth-last-child(5),
.gap-1 .flex-even:first-child:nth-last-child(5) ~ .flex-even {
  min-width: calc((100% - calc(0.25rem * calc(5 - 1))) / 5);
}
.gap-1 .flex-even:first-child:nth-last-child(6),
.gap-1 .flex-even:first-child:nth-last-child(6) ~ .flex-even {
  min-width: calc((100% - calc(0.25rem * calc(6 - 1))) / 6);
}
.gap-1 .flex-even:first-child:nth-last-child(7),
.gap-1 .flex-even:first-child:nth-last-child(7) ~ .flex-even {
  min-width: calc((100% - calc(0.25rem * calc(7 - 1))) / 7);
}
.gap-1 .flex-even:first-child:nth-last-child(8),
.gap-1 .flex-even:first-child:nth-last-child(8) ~ .flex-even {
  min-width: calc((100% - calc(0.25rem * calc(8 - 1))) / 8);
}

@media (min-width: 576px) {
  .gap-sm-1 {
    gap: 0.25rem;
  }
}
@media (min-width: 768px) {
  .gap-md-1 {
    gap: 0.25rem;
  }
}
@media (min-width: 992px) {
  .gap-lg-1 {
    gap: 0.25rem;
  }
}
@media (min-width: 1200px) {
  .gap-xl-1 {
    gap: 0.25rem;
  }
}
.gap-2 {
  gap: 0.5rem;
}
.gap-2 .flex-even {
  flex: 1;
}
.gap-2 .flex-even:first-child:nth-last-child(1),
.gap-2 .flex-even:first-child:nth-last-child(1) ~ .flex-even {
  min-width: calc((100% - calc(0.5rem * calc(1 - 1))) / 1);
}
.gap-2 .flex-even:first-child:nth-last-child(2),
.gap-2 .flex-even:first-child:nth-last-child(2) ~ .flex-even {
  min-width: calc((100% - calc(0.5rem * calc(2 - 1))) / 2);
}
.gap-2 .flex-even:first-child:nth-last-child(3),
.gap-2 .flex-even:first-child:nth-last-child(3) ~ .flex-even {
  min-width: calc((100% - calc(0.5rem * calc(3 - 1))) / 3);
}
.gap-2 .flex-even:first-child:nth-last-child(4),
.gap-2 .flex-even:first-child:nth-last-child(4) ~ .flex-even {
  min-width: calc((100% - calc(0.5rem * calc(4 - 1))) / 4);
}
.gap-2 .flex-even:first-child:nth-last-child(5),
.gap-2 .flex-even:first-child:nth-last-child(5) ~ .flex-even {
  min-width: calc((100% - calc(0.5rem * calc(5 - 1))) / 5);
}
.gap-2 .flex-even:first-child:nth-last-child(6),
.gap-2 .flex-even:first-child:nth-last-child(6) ~ .flex-even {
  min-width: calc((100% - calc(0.5rem * calc(6 - 1))) / 6);
}
.gap-2 .flex-even:first-child:nth-last-child(7),
.gap-2 .flex-even:first-child:nth-last-child(7) ~ .flex-even {
  min-width: calc((100% - calc(0.5rem * calc(7 - 1))) / 7);
}
.gap-2 .flex-even:first-child:nth-last-child(8),
.gap-2 .flex-even:first-child:nth-last-child(8) ~ .flex-even {
  min-width: calc((100% - calc(0.5rem * calc(8 - 1))) / 8);
}

@media (min-width: 576px) {
  .gap-sm-2 {
    gap: 0.5rem;
  }
}
@media (min-width: 768px) {
  .gap-md-2 {
    gap: 0.5rem;
  }
}
@media (min-width: 992px) {
  .gap-lg-2 {
    gap: 0.5rem;
  }
}
@media (min-width: 1200px) {
  .gap-xl-2 {
    gap: 0.5rem;
  }
}
.gap-3 {
  gap: 1rem;
}
.gap-3 .flex-even {
  flex: 1;
}
.gap-3 .flex-even:first-child:nth-last-child(1),
.gap-3 .flex-even:first-child:nth-last-child(1) ~ .flex-even {
  min-width: calc((100% - calc(1rem * calc(1 - 1))) / 1);
}
.gap-3 .flex-even:first-child:nth-last-child(2),
.gap-3 .flex-even:first-child:nth-last-child(2) ~ .flex-even {
  min-width: calc((100% - calc(1rem * calc(2 - 1))) / 2);
}
.gap-3 .flex-even:first-child:nth-last-child(3),
.gap-3 .flex-even:first-child:nth-last-child(3) ~ .flex-even {
  min-width: calc((100% - calc(1rem * calc(3 - 1))) / 3);
}
.gap-3 .flex-even:first-child:nth-last-child(4),
.gap-3 .flex-even:first-child:nth-last-child(4) ~ .flex-even {
  min-width: calc((100% - calc(1rem * calc(4 - 1))) / 4);
}
.gap-3 .flex-even:first-child:nth-last-child(5),
.gap-3 .flex-even:first-child:nth-last-child(5) ~ .flex-even {
  min-width: calc((100% - calc(1rem * calc(5 - 1))) / 5);
}
.gap-3 .flex-even:first-child:nth-last-child(6),
.gap-3 .flex-even:first-child:nth-last-child(6) ~ .flex-even {
  min-width: calc((100% - calc(1rem * calc(6 - 1))) / 6);
}
.gap-3 .flex-even:first-child:nth-last-child(7),
.gap-3 .flex-even:first-child:nth-last-child(7) ~ .flex-even {
  min-width: calc((100% - calc(1rem * calc(7 - 1))) / 7);
}
.gap-3 .flex-even:first-child:nth-last-child(8),
.gap-3 .flex-even:first-child:nth-last-child(8) ~ .flex-even {
  min-width: calc((100% - calc(1rem * calc(8 - 1))) / 8);
}

@media (min-width: 576px) {
  .gap-sm-3 {
    gap: 1rem;
  }
}
@media (min-width: 768px) {
  .gap-md-3 {
    gap: 1rem;
  }
}
@media (min-width: 992px) {
  .gap-lg-3 {
    gap: 1rem;
  }
}
@media (min-width: 1200px) {
  .gap-xl-3 {
    gap: 1rem;
  }
}
.gap-4 {
  gap: 1.5rem;
}
.gap-4 .flex-even {
  flex: 1;
}
.gap-4 .flex-even:first-child:nth-last-child(1),
.gap-4 .flex-even:first-child:nth-last-child(1) ~ .flex-even {
  min-width: calc((100% - calc(1.5rem * calc(1 - 1))) / 1);
}
.gap-4 .flex-even:first-child:nth-last-child(2),
.gap-4 .flex-even:first-child:nth-last-child(2) ~ .flex-even {
  min-width: calc((100% - calc(1.5rem * calc(2 - 1))) / 2);
}
.gap-4 .flex-even:first-child:nth-last-child(3),
.gap-4 .flex-even:first-child:nth-last-child(3) ~ .flex-even {
  min-width: calc((100% - calc(1.5rem * calc(3 - 1))) / 3);
}
.gap-4 .flex-even:first-child:nth-last-child(4),
.gap-4 .flex-even:first-child:nth-last-child(4) ~ .flex-even {
  min-width: calc((100% - calc(1.5rem * calc(4 - 1))) / 4);
}
.gap-4 .flex-even:first-child:nth-last-child(5),
.gap-4 .flex-even:first-child:nth-last-child(5) ~ .flex-even {
  min-width: calc((100% - calc(1.5rem * calc(5 - 1))) / 5);
}
.gap-4 .flex-even:first-child:nth-last-child(6),
.gap-4 .flex-even:first-child:nth-last-child(6) ~ .flex-even {
  min-width: calc((100% - calc(1.5rem * calc(6 - 1))) / 6);
}
.gap-4 .flex-even:first-child:nth-last-child(7),
.gap-4 .flex-even:first-child:nth-last-child(7) ~ .flex-even {
  min-width: calc((100% - calc(1.5rem * calc(7 - 1))) / 7);
}
.gap-4 .flex-even:first-child:nth-last-child(8),
.gap-4 .flex-even:first-child:nth-last-child(8) ~ .flex-even {
  min-width: calc((100% - calc(1.5rem * calc(8 - 1))) / 8);
}

@media (min-width: 576px) {
  .gap-sm-4 {
    gap: 1.5rem;
  }
}
@media (min-width: 768px) {
  .gap-md-4 {
    gap: 1.5rem;
  }
}
@media (min-width: 992px) {
  .gap-lg-4 {
    gap: 1.5rem;
  }
}
@media (min-width: 1200px) {
  .gap-xl-4 {
    gap: 1.5rem;
  }
}
.gap-5 {
  gap: 3rem;
}
.gap-5 .flex-even {
  flex: 1;
}
.gap-5 .flex-even:first-child:nth-last-child(1),
.gap-5 .flex-even:first-child:nth-last-child(1) ~ .flex-even {
  min-width: calc((100% - calc(3rem * calc(1 - 1))) / 1);
}
.gap-5 .flex-even:first-child:nth-last-child(2),
.gap-5 .flex-even:first-child:nth-last-child(2) ~ .flex-even {
  min-width: calc((100% - calc(3rem * calc(2 - 1))) / 2);
}
.gap-5 .flex-even:first-child:nth-last-child(3),
.gap-5 .flex-even:first-child:nth-last-child(3) ~ .flex-even {
  min-width: calc((100% - calc(3rem * calc(3 - 1))) / 3);
}
.gap-5 .flex-even:first-child:nth-last-child(4),
.gap-5 .flex-even:first-child:nth-last-child(4) ~ .flex-even {
  min-width: calc((100% - calc(3rem * calc(4 - 1))) / 4);
}
.gap-5 .flex-even:first-child:nth-last-child(5),
.gap-5 .flex-even:first-child:nth-last-child(5) ~ .flex-even {
  min-width: calc((100% - calc(3rem * calc(5 - 1))) / 5);
}
.gap-5 .flex-even:first-child:nth-last-child(6),
.gap-5 .flex-even:first-child:nth-last-child(6) ~ .flex-even {
  min-width: calc((100% - calc(3rem * calc(6 - 1))) / 6);
}
.gap-5 .flex-even:first-child:nth-last-child(7),
.gap-5 .flex-even:first-child:nth-last-child(7) ~ .flex-even {
  min-width: calc((100% - calc(3rem * calc(7 - 1))) / 7);
}
.gap-5 .flex-even:first-child:nth-last-child(8),
.gap-5 .flex-even:first-child:nth-last-child(8) ~ .flex-even {
  min-width: calc((100% - calc(3rem * calc(8 - 1))) / 8);
}

@media (min-width: 576px) {
  .gap-sm-5 {
    gap: 3rem;
  }
}
@media (min-width: 768px) {
  .gap-md-5 {
    gap: 3rem;
  }
}
@media (min-width: 992px) {
  .gap-lg-5 {
    gap: 3rem;
  }
}
@media (min-width: 1200px) {
  .gap-xl-5 {
    gap: 3rem;
  }
}
.text-decoration-none {
  text-decoration: none !important;
}

html {
  height: 100%;
  background-color: #e5e5e5;
}

body {
  height: 100%;
  margin: 0;
  padding: 0;
}

.full-height {
  height: 100% !important;
}

.app-header {
  display: block;
  position: relative;
  z-index: 1000;
  height: 50px;
  width: 100%;
  background-color: #fff;
}
.app-header .toggle-sidebar {
  display: block;
}

.nav-button {
  width: 40px !important;
  height: 40px !important;
}

@media only screen and (min-width: 992px) {
  .app-header .toggle-sidebar-btn {
    display: none;
  }
  .app-header .brand {
    display: inline-block;
    width: 250px;
    transition: padding 0.25s cubic-bezier(0, 0, 0.2, 1);
  }
  .app-header .brand a {
    text-decoration: none;
    font-weight: normal;
  }
  .nav-behind .app-header .brand {
    padding-left: 0;
  }
}
.app-sidebar {
  display: block;
  z-index: 99;
  left: 0;
  bottom: 0;
  top: 0;
  height: 100%;
  width: 250px;
  overflow: hidden;
  background-color: #292b2c;
}
.app-sidebar .sidebar-header {
  display: block;
  position: relative;
  height: 50px;
}
.app-sidebar .sidebar-footer {
  background-color: #292b2c;
}

@media only screen and (min-width: 992px) {
  .app-sidebar {
    z-index: 1001;
    overflow-x: hidden;
  }
}
.app-page-container {
  width: 100%;
  height: 100%;
}
.app-page-container .app-content-wrapper {
  min-height: 100%;
  position: relative;
  background-color: #fff;
}
.app-page-container .app-content-wrapper .app-content {
  z-index: 10;
  min-height: 100%;
  transition: all 0.3s ease;
}
.app-page-container .app-content-wrapper .app-content.full-width {
  width: 100%;
}

.app-page-container.scroll-disabled {
  overflow: hidden;
}

@media only screen and (max-width: 991px) {
  .app-page-container {
    z-index: 100;
    padding-left: 0;
    transition: transform 0.25s ease;
    background-color: #fff;
  }
  .app-page-container .app-content-wrapper .app-content {
    overflow-x: hidden;
  }
  my-app-sidenav {
    width: 0;
    transition: 0.1s ease-in-out;
    overflow: hidden;
  }
  .sidebar-mobile-open my-app-sidenav {
    width: 200px;
  }
}
@media only screen and (min-width: 992px) {
  .app-page-container .app-content-wrapper .app-footer {
    left: 250px;
  }
  .nav-collapsed .app-page-container .app-content-wrapper .app-footer {
    left: 130px;
  }
}
.app-page-container .app-footer {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  transition: left 0.3s cubic-bezier(0, 0, 0.2, 1);
}
.app-page-container .app-footer.fixed {
  position: fixed;
}

.app-page-container .app-footer {
  padding: 13px 0;
  border-top: 1px solid rgba(0, 0, 0, 0.05);
  font-size: 11px;
  line-height: 17px;
  color: #636c72;
}
.app-page-container .app-footer .brand {
  color: rgba(0, 0, 0, 0.87);
  text-transform: uppercase;
  letter-spacing: 0.02em;
}
.app-page-container .app-footer .material-icons {
  font-size: 0.875rem;
  vertical-align: text-top;
}

.quickview-wrapper {
  z-index: 1001;
  position: fixed;
  top: 0;
  bottom: 0;
  height: 100vh;
  right: -300px;
  width: 300px;
  background: #fff;
  transition: right 0.4s cubic-bezier(0.05, 0.74, 0.2, 0.99);
  backface-visibility: hidden;
}

.preview_hover {
  position: fixed;
  padding-left: 20px;
  z-index: 2;
  width: inherit;
}

.zoom_buttons i {
  font-size: 30px;
}

.zoomin {
  float: left;
  position: relative;
  z-index: 1;
}

.quickview-wrapper {
  border-left: 1px solid rgba(0, 0, 0, 0.05);
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
}

.quickview-survey-toggle {
  position: absolute;
  padding-top: 10%;
  padding-bottom: 10%;
  width: 20px;
  height: 40px;
  left: -48px;
  text-align: center;
  line-height: 50px;
  cursor: pointer;
}
.quickview-survey-toggle .material-icons {
  font-size: 16px;
  line-height: 50px;
}

.app-overlay {
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease, visibility 0.3s ease;
  z-index: 1040;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: #fff;
  overflow: hidden;
}
.app-overlay .overlay-close {
  position: absolute;
  right: 20px;
  top: 25px;
}

.overlay-active .app-overlay {
  visibility: visible;
  opacity: 1;
}

.app-main {
  max-width: 100%;
  margin: auto;
  transition: max-width 0.35s ease;
}
.app-main .app-sidebar {
  left: auto;
}
.app-main .app-header > .app-header-inner {
  max-width: 100%;
  margin: auto;
  transition: max-width 0.35s ease;
}

@media only screen and (min-width: 992px) {
  .layout-boxed.app-main {
    max-width: 1200px;
    box-shadow: 0 0 1px rgba(0, 0, 0, 0.2);
  }
  .layout-boxed.app-main .app-header > .app-header-inner {
    max-width: 1200px;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
  }
  .layout-boxed.app-main .app-overlay > .app-overlay-inner {
    max-width: 1200px;
    margin: auto;
  }
}
@media only screen and (max-width: 991px) {
  .no-app-sidebar + .app-page-container .app-header .header-icon {
    display: none;
  }
}
@media only screen and (min-width: 992px) {
  .no-app-sidebar + .app-page-container .app-header .brand {
    padding-left: 0;
  }
  .no-app-sidebar + .app-page-container .app-content-wrapper .app-content {
    padding-left: 0;
  }
  .no-app-sidebar + .app-page-container .app-content-wrapper .app-footer {
    left: 0;
  }
}
.app-sidebar ul.nav {
  flex-direction: column;
}
.app-sidebar ul.nav ul {
  display: none;
}
.app-sidebar ul.nav li {
  position: relative;
}
.app-sidebar ul.nav li.open > .icon-has-ul {
  transform: rotate(-180deg);
}
.app-sidebar ul.nav .icon-has-ul {
  position: absolute;
  top: 12px;
  right: 15px;
  font-size: 18px;
  line-height: 1;
  color: #777;
  transition: transform 0.3s ease-in-out;
}
.app-sidebar ul.nav ul .icon-has-ul {
  top: 11px;
}
.app-sidebar ul.nav .nav-divider {
  background-color: rgba(0, 0, 0, 0.15);
  min-height: 1px;
  margin: 10px 0;
  overflow: hidden;
}

.sidebar-header a.collapsednav-toggler {
  display: inline-block;
  position: absolute;
  width: 20px;
  height: 20px;
  line-height: 20px;
  text-align: center;
  right: 18px;
  top: 20px;
  color: rgba(255, 255, 255, 0.55);
}
.sidebar-header a.collapsednav-toggler .material-icons {
  font-size: 12px;
}

@media only screen and (max-width: 991px) {
  .app-sidebar .sidebar-header a.collapsednav-toggler {
    display: none;
  }
}
.app-sidebar .sidebar-header {
  text-align: left;
}
.app-sidebar .sidebar-header .logo-icon {
  margin-right: 11px;
}
.app-sidebar .sidebar-header .logo-img {
  margin-right: 12px;
}
.app-sidebar .sidebar-header .brand {
  display: inline;
}
.app-sidebar .sidebar-header .collapsednav-toggler {
  display: inline-block;
}
.app-sidebar .sidebar-content .nav-header {
  display: block;
}
.app-sidebar .sidebar-content .nav-text {
  display: inline;
}
.app-sidebar .sidebar-content .collapsed-text {
  display: none;
}
.app-sidebar .sidebar-content .icon-has-ul {
  display: inherit;
}
.app-sidebar .sidebar-content .badge {
  display: inherit;
  top: 14px;
  right: 35px;
}
.app-sidebar .sidebar-content .nav > li > a {
  padding: 10px 16px;
  text-align: left;
}
.app-sidebar .sidebar-content .nav > li > a .nav-icon {
  transition: padding 0.35s cubic-bezier(0, 0, 0.2, 1), margin 0.35s cubic-bezier(0, 0, 0.2, 1);
  margin-right: 18px;
}
.app-sidebar .sidebar-content .nav > li ul li > a {
  text-align: left;
}
.app-sidebar .sidebar-content .nav > li ul li > a > span {
  display: inline;
}
.app-sidebar .sidebar-content .nav > li ul li > a > .material-icons {
  transition: margin 0.35s cubic-bezier(0, 0, 0.2, 1);
  margin-right: 18px;
}
.app-sidebar .sidebar-footer .nav-text {
  display: inline;
}
.app-sidebar .sidebar-footer .collapsed-text {
  display: none;
}
.app-sidebar .sidebar-footer .nav > li > a {
  padding: 10px 16px;
  text-align: left;
}
.app-sidebar .sidebar-footer .nav > li > a .nav-icon {
  margin-right: 15px;
}

.justify-content-sidenav {
  justify-content: flex-start;
}

@media only screen and (min-width: 992px) {
  .app-sidebar {
    transition: width 0.3s cubic-bezier(0, 0, 0.2, 1);
  }
  .nav-collapsed .app-sidebar .justify-content-sidenav {
    justify-content: center;
  }
  .nav-collapsed .app-sidebar .sidebar-header {
    text-align: center;
  }
  .nav-collapsed .app-sidebar .sidebar-header .logo-icon,
  .nav-collapsed .app-sidebar .sidebar-header .logo-img {
    margin-right: 0;
  }
  .nav-collapsed .app-sidebar .sidebar-header .brand {
    display: none;
  }
  .nav-collapsed .app-sidebar .sidebar-header .collapsednav-toggler {
    display: none;
  }
  .nav-collapsed .app-sidebar .sidebar-content .nav-header, .nav-collapsed .app-sidebar .sidebar-content .nav-text, .nav-collapsed .app-sidebar .sidebar-content .icon-has-ul, .nav-collapsed .app-sidebar .sidebar-content .nav ul a > span {
    display: none;
  }
  .nav-collapsed .app-sidebar .sidebar-content .collapsed-text {
    display: block;
  }
  .nav-collapsed .app-sidebar .sidebar-content .badge {
    top: 3px;
    right: 5px;
  }
  .nav-collapsed .app-sidebar .sidebar-content .nav > li > a {
    padding: 12px 16px;
    text-align: center;
    height: 100%;
  }
  .nav-collapsed .app-sidebar .sidebar-content .nav > li .nav-icon {
    margin-right: 0;
  }
  .nav-collapsed .app-sidebar .sidebar-content .nav > li ul li > a {
    text-align: center;
  }
  .nav-collapsed .app-sidebar .sidebar-content .nav > li ul li > a > .material-icons {
    margin-right: 0;
  }
  .nav-collapsed .app-sidebar .sidebar-footer .collapsed-text {
    display: block;
  }
  .nav-collapsed .app-sidebar .sidebar-footer .nav > li > a {
    text-align: center;
  }
  .nav-collapsed .app-sidebar .sidebar-footer .nav > li .nav-icon {
    margin-right: 0;
  }
}
@media only screen and (min-width: 992px) {
  .nav-behind .app-sidebar {
    z-index: 999;
  }
}
@media only screen and (min-width: 992px) {
  .sidebar-sm.nav-collapsed .app-page-container .app-content-wrapper .app-content,
  .sidebar-lg.nav-collapsed .app-page-container .app-content-wrapper .app-content {
    padding-left: 130px;
  }
  .sidebar-sm.nav-collapsed .app-page-container .app-content-wrapper .app-footer,
  .sidebar-lg.nav-collapsed .app-page-container .app-content-wrapper .app-footer {
    left: 130px;
  }
}
@media only screen and (min-width: 992px) {
  .sidebar-sm.nav-collapsed .app-sidebar,
  .sidebar-lg.nav-collapsed .app-sidebar {
    width: 130px;
  }
}

@media only screen and (min-width: 992px) {
  .sidebar-sm .app-header .brand {
    width: 220px;
  }
}
@media only screen and (min-width: 992px) {
  .sidebar-sm .app-page-container .app-content-wrapper .app-content {
    padding-left: 220px;
  }
  .sidebar-sm .app-page-container .app-content-wrapper .app-footer {
    left: 220px;
  }
}
@media only screen and (max-width: 991px) {
  .sidebar-sm.sidebar-mobile-open .app-page-container {
    transform: translateX(220px);
  }
}
.sidebar-sm .app-sidebar {
  width: 220px;
}
@media only screen and (min-width: 992px) {
  .sidebar-lg .app-header .brand {
    width: 280px;
  }
}
@media only screen and (min-width: 992px) {
  .sidebar-lg .app-page-container .app-content-wrapper .app-content {
    padding-left: 280px;
  }
  .sidebar-lg .app-page-container .app-content-wrapper .app-footer {
    left: 280px;
  }
}
@media only screen and (max-width: 991px) {
  .sidebar-lg.sidebar-mobile-open .app-page-container {
    transform: translateX(280px);
  }
}
.sidebar-lg .app-sidebar {
  width: 280px;
}
@media only screen and (min-width: 992px) {
  .sidebar-lg.nav-collapsed .app-sidebar:hover {
    width: 280px;
  }
  .sidebar-lg.nav-collapsed .app-sidebar:hover > * {
    width: 280px;
  }
}

.app-header {
  padding: 0;
  border: 0;
  text-align: center;
}
.app-header .app-header-inner {
  height: 50px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
}
@media only screen and (min-width: 992px) {
  .app-header {
    text-align: inherit;
  }
}
@media only screen and (max-width: 992px) {
  .app-header .app-header-inner {
    box-shadow: none;
  }
}
.app-header.bg-transparent {
  background-color: transparent !important;
}
.app-header .brand {
  display: inline-block;
  text-align: center;
  float: left;
}
.app-header .brand h2 {
  font-size: 30px;
  margin: 0;
  line-height: 50px;
}
.app-header .header-icon {
  display: inline-block;
  height: 50px;
  padding: 0 14px;
}
@media only screen and (min-width: 992px) {
  .app-header .header-icon {
    padding-left: 18px;
    padding-right: 18px;
  }
}
.app-header .header-icon .material-icons {
  font-size: 24px;
}

.top-nav-left > ul > .list-inline-item,
.top-nav-right > ul > .list-inline-item {
  margin: 0;
  padding: 0;
}

.top-nav-left {
  display: inline-block;
}
.top-nav-left > ul {
  display: inline;
  margin: 0;
}

.top-nav-right {
  display: inline-block;
  font-size: 16px;
  line-height: 24px;
  float: right;
}
.top-nav-right ul {
  margin: 0;
}
.top-nav-right li {
  height: 50px;
  float: left;
}
.top-nav-right a:hover, .top-nav-right a:focus {
  text-decoration: none;
}

.logo-img {
  width: 24px;
  height: 24px;
  margin-bottom: -3px;
}
.logo-img .st1 {
  opacity: 0.9;
}

.bg-color-dark > .logo-img .st0,
.bg-color-primary > .logo-img .st0,
.bg-color-info > .logo-img .st0,
.bg-color-danger > .logo-img .st0,
.bg-color-success > .logo-img .st0 {
  fill: #fff;
}

.bg-color-warning > .logo-img .st0,
.bg-color-light > .logo-img .st0 {
  fill: rgba(0, 0, 0, 0.87);
}

.app-sidebar {
  box-shadow: 1px 0 2px rgba(0, 0, 0, 0.15);
}
.app-sidebar .sidebar-header {
  line-height: 50px;
  padding: 0 18px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
}
.app-sidebar .sidebar-header .logo-icon {
  text-align: center;
  font-size: 24px;
}
.app-sidebar .sidebar-header .logo-icon.material-icons {
  line-height: 50px;
  height: 50px;
  vertical-align: sub;
}
.app-sidebar .sidebar-header span img.img-responsive {
  height: 45px;
  display: inline-block;
  vertical-align: middle;
}
.app-sidebar .sidebar-header a.brand {
  display: inline-block;
  font-weight: normal;
  font-size: 23px;
  line-height: 50px;
  text-decoration: none;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 150px;
}
.app-sidebar .sidebar-footer {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  background-color: var(--secondary-color);
}

.app-sidebar .nav a {
  display: block;
  position: relative;
  text-decoration: none;
}
.app-sidebar .nav a:hover {
  cursor: pointer;
}
.app-sidebar .nav li {
  position: relative;
  padding: 6% 10%;
}
.app-sidebar .nav li .badge {
  position: absolute;
  padding: 3px 6px;
}
.app-sidebar .nav .nav-header {
  margin: 15px 15px 5px;
  font-size: 0.875rem;
}
.app-sidebar .nav .nav-divider + .nav-header {
  margin-top: 5px;
}
.app-sidebar .nav > li > a {
  line-height: 24px;
}
.app-sidebar .nav .nav-icon {
  display: inline-block;
}
.app-sidebar .nav .nav-icon.material-icons {
  width: 24px;
  height: 24px;
  font-size: 26px !important;
  line-height: 24px;
  text-align: center;
}
.app-sidebar .nav .nav-icon.material-icons.nav-dot {
  font-size: 16px;
}
.app-sidebar .nav ul {
  list-style: none;
  padding: 0;
}
.app-sidebar .nav ul li > a {
  padding: 10px 18px;
}
.app-sidebar .nav ul li > a .material-icons {
  width: 20px;
  height: 20px;
  font-size: 16px;
  line-height: 1;
  text-align: center;
  vertical-align: middle;
  margin-bottom: -2px;
}
.app-sidebar .nav ul ul li > a {
  padding: 10px 15px 10px 56px;
}
.app-sidebar .nav ul ul ul li > a {
  padding-left: 74px;
}

.app-sidebar {
  background-color: var(--secondary-color);
}
.app-sidebar .nav {
  color: var(--text-color);
}
.app-sidebar .nav a {
  color: var(--text-color);
}
.app-sidebar .nav .nav-header {
  color: #636c72;
}
.app-sidebar .nav .nav-divider {
  background-color: rgba(0, 0, 0, 0.15);
}
.app-sidebar .nav li > a:hover, .app-sidebar .nav li > a:focus {
  background-color: rgba(246, 249, 249, 0.3);
  color: var(--text-secondary);
}
.app-sidebar .nav li.active > a,
.app-sidebar .nav li.active > a:hover,
.app-sidebar .nav li.active > a:focus {
  background-color: transparent;
  color: var(--text-secondary);
}
.app-sidebar .nav li.open > a,
.app-sidebar .nav li.open > a:hover,
.app-sidebar .nav li.open > a:focus {
  background-color: rgba(0, 0, 0, 0.1);
  color: var(--text-secondary);
}
.app-sidebar .nav ul {
  background-color: rgba(0, 0, 0, 0.1);
}
.app-sidebar .nav ul li.active > a,
.app-sidebar .nav ul li.active > a:hover,
.app-sidebar .nav ul li.active > a:focus, .app-sidebar .nav ul li.open > a,
.app-sidebar .nav ul li.open > a:hover,
.app-sidebar .nav ul li.open > a:focus {
  background-color: rgba(0, 0, 0, 0.1);
}
.app-sidebar .nav ul ul {
  background-color: rgba(0, 0, 0, 0.1);
}
.app-sidebar .nav ul ul > li.active > a,
.app-sidebar .nav ul ul > li.active > a:hover,
.app-sidebar .nav ul ul > li.active > a:focus, .app-sidebar .nav ul ul > li.open > a,
.app-sidebar .nav ul ul > li.open > a:hover,
.app-sidebar .nav ul ul > li.open > a:focus {
  background-color: rgba(0, 0, 0, 0.1);
  color: var(--text-secondary);
}
.app-sidebar .nav ul ul ul {
  background-color: rgba(0, 0, 0, 0.1);
}

.app-overlay .app-overlay-inner {
  max-width: 1090px;
  margin: 0 auto;
  padding: 20px 30px;
}
@media only screen and (min-width: 768px) {
  .app-overlay .app-overlay-inner {
    padding: 20px 100px;
  }
}
.app-overlay input.overlay-search-input {
  border: 0;
  background-color: transparent;
  font-size: 35px;
  font-weight: normal;
  width: 100%;
  padding-left: 0;
  line-height: 1;
}
@media only screen and (min-width: 768px) {
  .app-overlay input.overlay-search-input {
    font-size: 70px;
  }
}
.app-overlay input.overlay-search-input:focus {
  outline: none;
}
.app-overlay .overlay-header {
  position: relative;
  padding: 60px 0 0;
}
.app-overlay .overlay-header h2 {
  font-size: 18px;
  font-weight: normal;
  margin: 0;
}
@media only screen and (min-width: 768px) {
  .app-overlay .overlay-header h2 {
    font-size: 24px;
  }
}
.app-overlay a.overlay-close {
  position: absolute;
  top: 0;
  right: 10px;
  font-weight: 300;
}
.app-overlay a.overlay-close .material-icons {
  font-size: 32px;
}
.app-overlay .overlay-content {
  margin: 12px 0 0;
}

.app-overlay {
  background: rgba(255, 255, 255, 0.9);
}
.app-overlay a.overlay-close {
  color: rgba(0, 0, 0, 0.87);
}

.quickview-open-app #quickview-app {
  right: 0;
}

.quickview-app .quickview-close {
  display: inline-block;
  z-index: 1;
  position: absolute;
  top: 11px;
  right: 15px;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.87);
  opacity: 0.6;
}
.quickview-app .quickview-close .material-icons {
  font-size: 1rem;
  vertical-align: middle;
}
.quickview-app .quickview-close:hover {
  opacity: 1;
}

#quickview-customizer {
  width: 410px;
  right: -410px;
}

.quickview-open-customizer #quickview-customizer {
  right: 0;
}

.customizer {
  padding: 0;
  background-color: #fafafa;
}
.customizer .quickview-inner {
  padding: 15px 45px;
}
.customizer .customizer-header {
  text-transform: uppercase;
  margin-bottom: 3px;
}
.customizer h4.section-header {
  margin: 12px 0 0;
  font-size: 16px;
  line-height: 1.35;
  font-weight: normal;
}
.customizer a {
  position: relative;
  display: block;
  width: 100%;
  color: rgba(0, 0, 0, 0.87);
}
.customizer .customizer-close {
  position: absolute;
  right: 10px;
  top: 10px;
  padding: 7px;
  width: auto;
  z-index: 10;
}
.customizer .customizer-close .material-icons {
  font-size: 20px;
}
.customizer a.customizer-toggle,
.customizer a.customizer-close {
  color: rgba(0, 0, 0, 0.87);
}
.customizer a.customizer-toggle:hover, .customizer a.customizer-toggle:focus,
.customizer a.customizer-close:hover,
.customizer a.customizer-close:focus {
  color: rgba(0, 0, 0, 0.87);
}
.customizer .customizer-toggle {
  position: absolute;
  top: 25%;
  width: 54px;
  height: 50px;
  left: -48px;
  text-align: center;
  line-height: 50px;
  cursor: pointer;
}
.customizer .customizer-toggle .material-icons {
  font-size: 16px;
  line-height: 50px;
}
.customizer:after {
  position: absolute;
  top: 25%;
  left: 0;
  content: "";
  width: 5px;
  height: 50px;
  background-color: #fafafa;
}
.customizer md-list {
  padding: 0;
}
.customizer md-list-item, .customizer md-list-item ._md-list-item-inner {
  min-height: 40px;
}
.customizer md-list-item ._md-no-style, .customizer md-list-item._md-no-proxy {
  padding: 0 5px;
}

.theme-gray .customizer a,
.theme-dark .customizer a {
  color: rgba(255, 255, 255, 0.7);
}
.theme-gray .customizer a:hover, .theme-gray .customizer a:focus,
.theme-dark .customizer a:hover,
.theme-dark .customizer a:focus {
  color: rgba(255, 255, 255, 0.7);
}

.theme-dark .customizer {
  background-color: linear-gradient(to top, rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05), var(--secondary-color));
}
.theme-dark .customizer:before {
  background-color: linear-gradient(to top, rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05), var(--secondary-color));
}
.theme-dark .customizer:after {
  background-color: linear-gradient(to top, rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05), var(--secondary-color));
}

.theme-gray .customizer {
  background-color: hsl(var(var(linear-gradient(to top, rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05), var(--secondary-color)))-h), calc(var(var(linear-gradient(to top, rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05), var(--secondary-color)))-s) * 0), var(var(linear-gradient(to top, rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05), var(--secondary-color)))-l));
}
.theme-gray .customizer:before {
  background-color: hsl(var(var(linear-gradient(to top, rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05), var(--secondary-color)))-h), calc(var(var(linear-gradient(to top, rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05), var(--secondary-color)))-s) * 0), var(var(linear-gradient(to top, rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05), var(--secondary-color)))-l));
}
.theme-gray .customizer:after {
  background-color: hsl(var(var(linear-gradient(to top, rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05), var(--secondary-color)))-h), calc(var(var(linear-gradient(to top, rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05), var(--secondary-color)))-s) * 0), var(var(linear-gradient(to top, rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05), var(--secondary-color)))-l));
}

.top-nav-left li .header-btn.md-button,
.top-nav-right li .header-btn.md-button {
  margin: 0;
  line-height: 50px;
  border-radius: 0;
  min-width: 52px;
}
@media only screen and (min-width: 992px) {
  .top-nav-left li .header-btn.md-button,
  .top-nav-right li .header-btn.md-button {
    min-width: 60px;
  }
}
.top-nav-left li .header-btn.md-button > .material-icons,
.top-nav-right li .header-btn.md-button > .material-icons {
  vertical-align: middle;
}
.top-nav-left li .header-btn.md-button .badge,
.top-nav-right li .header-btn.md-button .badge {
  background-color: transparent;
  position: absolute;
  top: 6px;
  right: 3px;
  color: inherit;
}

.app-sidebar .md-button {
  margin: 0;
  text-align: left;
  text-transform: none;
  border-radius: 0;
  font-weight: normal;
  line-height: inherit;
  min-height: inherit;
  min-width: inherit;
}

.quickview-app md-tabs > md-tabs-wrapper {
  background-color: #eee;
  padding: 0 40px;
}
.quickview-app md-tabs md-pagination-wrapper {
  width: 100% !important;
}

md-backdrop.md-sidenav-backdrop,
.md-sidenav-right {
  z-index: 1001;
}

.md-sidenav-right .md-sidenav-inner {
  height: 100%;
}
.md-sidenav-right .md-sidenav-inner > md-tabs {
  height: 100%;
}
.md-sidenav-right md-tabs-canvas > md-pagination-wrapper {
  width: 100% !important;
}
.md-sidenav-right md-tabs-canvas > md-pagination-wrapper > md-tab-item {
  width: 50%;
}
.md-sidenav-right md-tabs-canvas > md-pagination-wrapper md-ink-bar {
  color: var(--primary-color);
  background: var(--primary-color);
}