
// validation
md-input-container:not(.ng-pristine) {
  &.ng-invalid {
    .mat-input-underline {
      border-color: $brand-danger;
      .mat-input-ripple {
        background-color: $brand-danger;
      }
    }
  }
  &.ng-valid {
    .mat-input-underline {
      border-color: $brand-success;
      .mat-input-ripple {
        background-color: $brand-success;
      }
    }
  }
}

