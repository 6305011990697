$text-color: #fff;
$active-color: var(--primary-color);
$inactive-color: #000;
$stroke-width: 1px;
$radio-size: 0.4em;
$bg-color: #000;
$duration: 0.5s;

.marginLeft1em{margin-left:1em;}
.doctorImg{max-width: 100%;}
.marginLeft06em{margin-left:0.6em;}
.marginLeft1em{margin-left:1em;}
.fixIcon{margin-top:1em;color:black;}
.fixIcon2{color:black;}
.paddingTopBottom{padding-top:0.5em;padding-bottom:0.5em;}
.maxImgSize{max-width:5em;max-height:5em;}
.largeIcon{font-size:5em;height:1em;width:1em;}
.overflowScroll{overflow-x:scroll;}
.marginBottom1em{margin-bottom:1em;}
.padding04em{padding-top:0.4em;}
.whiteSpaceNormal{white-space: normal;}
.mat-horizontal-content-container{padding: 0;}

.hr-text2 {
  border-top: 0 !important;
  line-height: 1em;
  position: relative;
  outline: 0;
  border: 0;
  color: black;
  text-align: center;
  height: 1.5em;
  opacity: .5;
  &:before {
    content: '';
    // use the linear-gradient for the fading effect
    // use a solid background color for a solid bar
    background: linear-gradient(to right, transparent, black, transparent);
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    height: 1px;
  }
  &:after {
    content: attr(data-content);
    position: relative;
    display: inline-block;

    padding: 0 .5em;
    line-height: 1.5em;
    // this is really the only tricky part, you need to specify the background color of the container element...
    color: black;
    background-color: #f2f5f7;
  }
}

#virtual-care-booking {
  overflow-y: hidden;
}

.fixed-content {
  overflow: hidden;
  position: fixed;
  width: 100%;
  z-index: 3;
}

.scrollable-content {
  position: absolute;
  width: 100%;
}

.full-height-minus-header {
  height: -moz-calc(100vh - 64px) !important;
  height: -webkit-calc(100vh - 64px) !important;
  height: calc(100vh - 64px) !important;
  display: block;
  overflow-y: hidden;
}

.timeSlot {
  // border-color: #f96d64 !important;
  width: 70%;
  border-radius: 20px;
  text-align: center;
  padding: 7px 0;
  line-height: 1.5;
}

.mat-step-header {
  height: 45px;
}

.mat-step-icon {
  background-color: white;
  color: black;
}

.mat-horizontal-content-container {
  padding: 0;
}

.insig-horizontal-stepper {
  background-color: transparent !important;

  .horizontal-stepper-header-container {
    .mat-step-header .mat-step-icon {
      background-color: white !important;
      color: #000;
    }
  }

}

.fixed-bottom-toolbar {
  position: fixed;
  height: 45px;
  bottom: 0;
  z-index: 10;
  min-height: auto;

  .mat-toolbar-row {
    height: 45px;
  }
}

.toolbar-transparent {
  background-color: transparent !important;
  color: black !important;

  .mat-step-header .mat-step-icon {
    background-color: var(--primary-color);
    color: white;
  }

  .step {
    box-shadow: inset 0 0 0 $stroke-width $inactive-color, inset 0 0 0 0 $bg-color, inset 0 0 0 $radio-size $inactive-color;
  }

  .state-checked {
    box-shadow: inset 0 0 0 $stroke-width $active-color, inset 0 0 0 0 $bg-color, inset 0 0 0 $radio-size $active-color;
  }
}


.scheduler-confirmation {
  background: url('/assets/images/app/virtual-care/waiting_room.svg') no-repeat center center fixed;
  background-size: cover;
  background-position: bottom;
  width: 100%;
  background-repeat: no-repeat;
}

.virtual-media-select-bg {
  background-color:white;
  // background-image: url('/assets/images/app/virtual-care/media-select.svg');
  // background-size: contain;
  // background-position: bottom;
  width: 100%;
  background-repeat: no-repeat;
}

.virtual-confirmation-bg {
    background-image: url('/assets/images/app/virtual-care/waiting_room.svg');
    background-size: cover;
    background-position: bottom;
    width: 100%;
    background-repeat: no-repeat;
}

@media (max-width: 544px){
  .virtual-confirmation-bg {
    background-image: none !important;
  }
}


.virtual-family-select-bg {
  background-color:white;
  background-image: url('/assets/images/app/virtual-care/family-select.svg');
  background-size: contain;
  background-position: bottom;
  width: 100%;
  background-repeat: no-repeat;
}
@media (max-width: 544px){
  .virtual-family-select-bg {
    background-image: none !important;
  }
}


.virtual-company-select-bg {
  background-image: url('/assets/images/app/virtual-care/company-select.svg');
  background-size: cover;
  background-position: bottom;
  width: 100%;
  background-repeat: no-repeat;
}

@media (max-width: 544px){
  .virtual-company-select-bg {
    background-image: none !important;
  }
}


.side-box-shadow {
  box-shadow: 6px 0 6px 0 rgba(0, 0, 0, 0.1);
}

.appt-card-header {
  flex: 1;
  font-weight: 800;
}

.appt-card-price {
  float: right;
}


.overflow-container {
  height: 100vh !important;
  overflow-y: auto;
}

.hover-grey:hover {
  background-color: #f1f1f1;
}

.logo-bottom {
  position: fixed;
  bottom: 2%;
  left: 2%;
}

.floating-filters {
  position: fixed;
  top: 10%;
  height: 120px;
  padding-left: 33%;
  padding-right: 10%;

  .mat-card {
    padding-top: 0;
    text-align: center;
  }
}

.search-bar-nav {
  position: relative;
  padding-top: 15px;

  .material-icons {
    position: absolute;
    left: 15px;
    top: 23px;
    z-index: 999;
  }

  input {
    font: 16px/1.875 "Avenir Next W01", "Avenir Next", "Helvetica Neue", Helvetica, sans-serif;
    height: 40px;
    border: 1px solid #f1f1f7;
    border-radius: 4px;
    padding-left: 50px;
  }
}

.button {
  flex: 1 1 auto;
  color: var(--primary-color);
  margin: 10px auto;
  padding: 20px;
  border: 2px solid var(--primary-color);
  text-align: center;
  text-transform: uppercase;
  max-width: 300px;
  // position: relative;
  overflow: hidden;
  transition: 0.3s;
  transform: scale(0.9);

  &:after {
    // position: absolute;
    transition: 0.3s;
    content: '';
    width: 0;
    left: 50%;
    bottom: 0;
    height: 3px;
    background: var(--primary-color);
  }

  &:hover {
    cursor: pointer;

    &:after {
      width: 100%;
      left: 0;
    }
    transform: scale(1);
  }
}

.button-ter {
  flex: 1 1 auto;
  background-color: var(--primary-color);
  color: #fff;
  margin: 10px auto;
  padding: 20px;
  border: 2px solid #fff;
  text-align: center;
  text-transform: uppercase;
  max-width: 300px;
  // position: relative;
  overflow: hidden;
  transition: 0.3s;
  transform: scale(0.9);

  &:after {
    // position: absolute;
    transition: 0.3s;
    content: '';
    width: 0;
    left: 50%;
    bottom: 0;
    height: 3px;
    background: var(--primary-color);
  }

  &:hover {
    cursor: pointer;

    &:after {
      width: 100%;
      left: 0;
    }
    transform: scale(1);
  }
}


.button-secondary {
  flex: 1 1 auto;
  background-color: var(--primary-color);
  color: #fff;
  margin: 10px auto;
  padding: 20px;
  border: 2px solid #fff;
  text-align: center;
  text-transform: uppercase;
  max-width: 300px;
  max-height: 65px;
  // position: relative;
  overflow: hidden;
  transition: 0.3s;
  transform: scale(0.9);
  font-family: 'Roboto';
  font-size: 20px;
  border-radius: 10px;

  &:after {
    // position: absolute;
    transition: 0.3s;
    content: '';
    width: 0;
    left: 50%;
    bottom: 0;
    height: 3px;
    background: var(--primary-color);
  }

  &:hover {
    cursor: pointer;

    &:after {
      width: 100%;
      left: 0;
    }
    transform: scale(1);
  }
}

.button-disabled {
  flex: 1 1 auto;
  background-color: #e5e5e5;
  margin: 10px auto;
  padding: 20px;
  border: 2px solid #fff;
  text-align: center;
  text-transform: uppercase;
  max-width: 300px;
  // position: relative;
  overflow: hidden;
  transition: 0.3s;
  transform: scale(0.9);

  &:after {
    // position: absolute;
    transition: 0.3s;
    content: '';
    width: 0;
    left: 50%;
    bottom: 0;
    height: 3px;
    background: var(--primary-color);
  }
}

.button-tertiary {
  flex: 1 1 auto;
  background-color: #fff;
  color: #fff;
  color: #fff;
  margin: 10px auto;
  padding: 20px;
  // border: 2px solid #fff;
  text-align: center;
  text-transform: uppercase;
  max-width: 300px;
  // position: relative;
  overflow: hidden;
  transition: 0.3s;
  transform: scale(0.9);

  &:after {
    // position: absolute;
    transition: 0.3s;
    content: '';
    width: 0;
    left: 50%;
    bottom: 0;
    height: 3px;
    background: var(--primary-color);
    // background-color: #fff;
  }
}

.button-white {
  flex: 1 1 auto;
  background-color: #fff;
  // color: #fff;
  margin: 10px auto;
  padding: 20px;
  // border: 2px solid #fff;
  text-align: center;
  text-transform: uppercase;
  max-width: 300px;
  // position: relative;
  overflow: hidden;
  transition: 0.3s;
  transform: scale(0.9);
  border: solid;
  border-width: 2px;

  &:after {
    // position: absolute;
    transition: 0.3s;
    content: '';
    width: 0;
    left: 50%;
    bottom: 0;
    height: 3px;
    background-color: #fff;
  }

  &:hover {
    cursor: pointer;

    &:after {
      width: 100%;
      left: 0;
    }
    transform: scale(1);
  }
}

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1010;
  display: none;
  max-width: 276px;
  padding: 1px;
  text-align: left;
  background-color: #fff;
  -webkit-background-clip: padding-box;
  -moz-background-clip: padding;
  background-clip: padding-box;
  border: 1px solid #ccc;
  border: 1px solid rgba(0, 0, 0, .2);
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
  -webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, .2);
  -moz-box-shadow: 0 5px 10px rgba(0, 0, 0, .2);
  box-shadow: 0 5px 10px rgba(0, 0, 0, .2);
  white-space: normal;
}

.popover.top {
  margin-top: -10px;
}

.popover.right {
  margin-left: 10px;
}

.popover.bottom {
  margin-top: 10px;
}

.popover.left {
  margin-left: -10px;
}

.popover-title {
  margin: 0;
  padding: 8px 14px;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  -webkit-border-radius: 5px 5px 0 0;
  -moz-border-radius: 5px 5px 0 0;
  border-radius: 5px 5px 0 0;
}

.popover-title:empty {
  display: none;
}

.popover-content {
  padding: 9px 14px;
}

.popover .arrow,
.popover .arrow:after {
  position: absolute;
  display: block;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
}

.popover .arrow {
  border-width: 11px;
}

.popover .arrow:after {
  border-width: 10px;
  content: "";
}

.popover.top .arrow {
  left: 50%;
  margin-left: -11px;
  border-bottom-width: 0;
  border-top-color: #999;
  border-top-color: rgba(0, 0, 0, .25);
  bottom: -11px;
}

.popover.top .arrow:after {
  bottom: 1px;
  margin-left: -10px;
  border-bottom-width: 0;
  border-top-color: #fff;
}

.popover.right .arrow {
  top: 50%;
  left: -11px;
  margin-top: -11px;
  border-left-width: 0;
  border-right-color: #999;
  border-right-color: rgba(0, 0, 0, .25);
}

.popover.right .arrow:after {
  left: 1px;
  bottom: -10px;
  border-left-width: 0;
  border-right-color: #fff;
}

.popover.bottom .arrow {
  left: 50%;
  margin-left: -11px;
  border-top-width: 0;
  border-bottom-color: #999;
  border-bottom-color: rgba(0, 0, 0, .25);
  top: -11px;
}

.popover.bottom .arrow:after {
  top: 1px;
  margin-left: -10px;
  border-top-width: 0;
  border-bottom-color: #fff;
}

.popover.left .arrow {
  top: 50%;
  right: -11px;
  margin-top: -11px;
  border-right-width: 0;
  border-left-color: #999;
  border-left-color: rgba(0, 0, 0, .25);
}

.popover.left .arrow:after {
  right: 1px;
  border-right-width: 0;
  border-left-color: #fff;
  bottom: -10px;
}
@media screen and (min-width: 768px) {
  .padding-top-desktop {
    padding-top: 150px !important;
  }
}
